import React, { useEffect, useRef } from "react";
import "./index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export function ModalAlternativoHtml({children, ...props}){
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        props.close()
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [ref, props]);

  return(
    props?.open ? 
      <div className="modal-alterantivo-html">
        <div className="backdrop"></div>
        <div className="modal-info" ref={ref}>
          <span className="btn-close" onClick={()=>{props.close()}}><FontAwesomeIcon icon={faTimes}/></span>
          {children}
        </div>
      </div>
    : <></>
  )
}
