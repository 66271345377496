import React, { useEffect, useState } from 'react'
import ModalDommus from '../Modal'
import ModalHtml from '../../pages/Eventos/ModalHTML'
import ModalImagem from '../../pages/Eventos/ModalImagem';
import Eventos from '../../pages/Eventos';
import { ModalAlternativoHtml } from '../ModalAlternativoHtml';
import ModalBanner from '../../pages/Eventos/ModalBanner';


function ModalMap({ tipo, modalState, cor, conteudo, handleModalEvento = null }) {
  const [openModalHTML, setOpenModalHTML] = useState(modalState);
  const [modal, setModal] = useState(modalState);


  useEffect(() => {
    setOpenModalHTML(modalState)
    identificaModal()
  }, [modalState])

  function identificaModal() {
    if (tipo == 'html') {
      setModal(<>
        <ModalHtml
          cor={cor}
          evento={conteudo}
          handleModalHtmlEvento={handleModalEvento} />
      </>)
    } else if (tipo == 'janela') {
      setModal(<>
        <Eventos
          cor={cor}
          dados={conteudo} />
      </>)
    } else if (tipo =='imagem') {
      setModal(<>
        <ModalImagem
          cor={cor}
          evento={conteudo}
          abrirModalFocoImagens={handleModalEvento} />
      </>)
    } else if (tipo === 'banner'){
      setModal(<>
        <ModalBanner
          cor={cor}
          evento={conteudo}
          handleModalHtmlEvento={handleModalEvento} />
      </>)
    }
  }
  return (
    tipo === 'banner' ? 
      <ModalAlternativoHtml 
        open={openModalHTML}
        close={() => {
          setOpenModalHTML(false)
        }}
      >
        {modal}
      </ModalAlternativoHtml>
    :
      <ModalDommus
        className="modalHtml"
        open={openModalHTML}
        size={'md'}
        close={() => {
          setOpenModalHTML(false)
        }}
        titulo={"Evento"}
      >
        {modal}
      </ModalDommus>
  )
}

export default ModalMap
