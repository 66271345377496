import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { Icon } from "@iconify/react";
import ModalDommus from "../../../components/Modal";
import {
  IconButton,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
} from "@material-ui/core/";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons/";
import LastPageIcon from "@material-ui/icons/LastPage";
import arrowRightCircle from "@iconify-icons/bi/arrow-right-circle";
import Chamado from "./Chamado";
import "./chamados.css";
import ticketService from "../../../services/TicketService";
import guService from "../../../services/GuService";
import { trackPromise } from "react-promise-tracker";
import helper from "../../../helpers/format";
import moment from "moment";
import configuracaoService from "../../../services/ConfiguracaoService";
import ModalDommusFullScreen from "../../../components/ModalFullScreen";
import { isDesktop } from "react-device-detect";
import menuService from "../../../services/MenuService";
import { ConfiguracoesVisuaisContextContext } from "../../../contexts/ConfiguracoesVisuaisContext";
import { LogNavegacaoService } from "../../../services/LogNavegacaoService";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
  },
}));
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#887d7d",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 16,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#DDDDDD",
    },
  },
}))(TableRow);
function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;
  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };
  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };
  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };
  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };
  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
const useStyles2 = makeStyles({
  table: {
    minWidth: 550,
  },
});
export default function CustomPaginationActionsTable({ cor, refresh, idWidget }) {
  const { aplicarEstilosMobile } = useContext(ConfiguracoesVisuaisContextContext)
  const [iconPago, setIconPago] = useState(true);
  const classes = useStyles2();
  const [page, setPage] = useState(0);
  const usuarioTipo = guService.getLocalState('usuarioTipo')
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openModal, setOpenModal] = useState(false);
  const [openId, setOpenId] = useState([]);
  const [rows, setRows] = useState([]);
  const [ticketSelecionado, setTicketSelecionado] = useState({});
  const [exibirPrazo, setExibirPrazo] = useState(true);


  async function buscarTicketPorProcesso() {
    return await trackPromise(ticketService.index(guService.getLocalState("Processo")))
  }
  async function buscarTicketsPorEmpreendimento() {
    return await ticketService.buscarChamadosAreaComum({ 
      empreendimentos: [guService.getLocalState("IdEmpreendimento")],
      tipo_chamado: ['AC']
    })
  }
  async function carregaLista() {
    let listaLinhas = [];
    let tickets = []
    if(usuarioTipo == menuService.TIPO_USUARIO_SINDICO_CONDOMINIO) {
      tickets = await buscarTicketsPorEmpreendimento()
    } else {
      tickets = await buscarTicketPorProcesso()
    }
    tickets.data.forEach(function (item) {
      var linha = {
        protocolo:
          item.tipo.sigla + "-" + helper.zeroPad(item.id_ticket, 5),
        data: moment(item.criado_em).format("DD/MM/YYYY"),
        hora: moment(item.criado_em).format("HH:mm"),
        opv_processo: item.id_processo && helper.zeroPad(item.id_processo, 5) || 'Área Comum',
        assunto: item.solicitante,
        assunto: item.assunto,
        categoria: item.categoria.nome,
        motivo: item.motivo.nome,
        prazo: item.prazo_slo,
        status: (
          <span className="MuiBadge-root chamadoStatus2">
            <span
              className="MuiBadge-badge MuiBadge-anchorOriginTopRightRectangle MuiBadge-colorError badgeBase"
              style={{ backgroundColor: item.status.cor }}
            >
              {item.status.nome_portal_cliente || item.status.nome}
            </span>
          </span>
        ),
        acessar: (
          <div className="acessar">
            {" "}
            <Icon
              onClick={() => {
                LogNavegacaoService.gravarLogNavegacao(LogNavegacaoService.NOME_COMPONENTE_WIDGET, idWidget, { idTicket: item.id_ticket })
                setOpenModal(true);
                setTicketSelecionado(item);
              }}
              icon={arrowRightCircle}
              width="30px"
              height="30px"
              color="gray"
            />
          </div>
        ),
      };
      listaLinhas.push(linha);
    });
    setRows(listaLinhas);
  }
  useEffect(() => {
    configuracaoService.buscarConfiguracoesVisuais().then((response) => {
      const exibirPrazoAux = response.data.filter(configuracao => {
        return configuracao.chave === "exibir_informacoes_prazo";
      });
      setExibirPrazo(exibirPrazoAux[0]?.valor)
  });
  }, [])
  
  useEffect(() => {
    carregaLista();
  }, [refresh]);
  
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  function calculaPrazo(sla) {
    let slaEmDias = Math.floor(sla / 60 / 24);
    let prazoLimite = moment(new Date()).add(Number(sla), "minutes");
    return (
      prazoLimite.format("DD/MM/YYYY") + " às " + prazoLimite.format("HH:mm")
    );
  }
 

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ backgroundColor: cor }} align="center">
                Protocolo
              </StyledTableCell>
              <StyledTableCell style={{ backgroundColor: cor, width: "40%" }}>
                Assunto
              </StyledTableCell>
              {exibirPrazo && <>
                <StyledTableCell style={{ backgroundColor: cor }} align="center">
                  Prazo
                </StyledTableCell>
              </>}
              <StyledTableCell
                style={{ backgroundColor: cor, width: "20%" }}
                align="center"
              >
                Status
              </StyledTableCell>
              <StyledTableCell
                style={{ backgroundColor: cor, width: "10%" }}
                align="center"
              >
                Acessar
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row, index) => (
              <StyledTableRow key={index}>
                <TableCell
                  component="th"
                  style={{ width: 20 }}
                  scope="row"
                  align="center"
                >
                  <b>{row.protocolo}</b> <br />
                  {row.data} <br />
                  {row.hora}
                </TableCell>
                <TableCell style={{ padding: 0 }}>{row.assunto}</TableCell>
                {exibirPrazo && <>
                <TableCell style={{ width: 115, padding: 0 }} align="center">
                  {moment(row.prazo).format("DD/MM/YYYY")}
                  <br />
                  {moment(row.prazo).format("HH:mm")}
                </TableCell> </>}
                <TableCell style={{ padding: 0 }} align="center">
                  {row.status}
                </TableCell>
                <TableCell style={{ padding: 0 }} align="center">
                  {row.acessar}
                </TableCell>
              </StyledTableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 60 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter className="pagination">
            <TableRow>
              <TablePagination
                labelRowsPerPage="Linhas por página"
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={3}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "linhas por pagina" },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {isDesktop || !aplicarEstilosMobile ? 
        <ModalDommus
          open={openModal}
          className="modalchamado"
          size={"hg"}
          close={() => {
            setOpenModal(false);
          }}
          titulo={`Chamado Nº: ` + helper.zeroPad(ticketSelecionado.id_ticket, 5)}
        >
          <Chamado chamado={ticketSelecionado} />
        </ModalDommus>
      :
        <ModalDommusFullScreen
          open={openModal}
          className="modalchamado"
          size={"hg"}
          close={() => {
            setOpenModal(false);
          }}
          titulo={`Chamado Nº: ` + helper.zeroPad(ticketSelecionado.id_ticket, 5)}
        >
          <Chamado chamado={ticketSelecionado} />
        </ModalDommusFullScreen>
      }
    </>
  );
}
