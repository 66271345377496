/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useContext } from "react";
import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import "./index.css";
import Select from "react-select";
import DommusRequiredSpan from "../../components/DommusRequiredSpan";
import ticketMotivo from "../../services/TicketMotivoService";
import ticketService from "../../services/TicketService";
import guService from "../../services/GuService";
import { useForm } from "react-hook-form";
import { trackPromise } from "react-promise-tracker";
import moment from "moment";
import helper from "../../helpers/format";
import { errorToast } from "../DommusToast";
import ValidaTipoArquivo from "../../helpers/ValidaTipoArquivo";
import swal from "sweetalert";
import Swal from "sweetalert2";
import '../../pages/MeuImovel/AbrirChamado/index.css'
import DataHelper from "../../helpers/DataHelper";
import menuService from "../../services/MenuService";
import { DommusMultiFileInput } from "../DommusMultiFileInput";

export default function NovoChamado({
  cor,
  ticket,
  titulo,
  mostrarBtn,
  textoBtn,
  disableMotivo,
  setRefresh,
  close,
}) {
  const [motivosOptions, setMotivosOptions] = useState([]);
  const [assunto, setAssunto] = useState("");
  const [motivo, setMotivo] = useState(ticket ? ticket : "");
  const [descricao, setDescricao] = useState("");
  const [link, setLink] = useState("");
  const [botaoAbrirChamadoInativo, setBotaoAbrirChamadoInativo] = useState(false)
  const [arquivo, setArquivo] = useState({});
  const [errorAssunto, setErrorAssunto] = useState(false);
  const [errorMotivo, setErrorMotivo] = useState(false);
  const [errorDescricao, setErrorDescricao] = useState(false);
  const [configuracoesCriacao, setConfiguracoesCriacao] = useState({});

  const [liberado, setLiberado] = useState(true);
  const [carregou, setCarregou] = useState(false);

  const { handleSubmit, register } = useForm();
  
  function verificaError() {
    if (assunto === "" || assunto == null) {
      setErrorAssunto(true);
    } else {
      setErrorAssunto(false);
    }
    if (motivo === "" || motivo == null) {
      setErrorMotivo(true);
    } else {
      setErrorMotivo(false);
    }
    if (descricao === "") {
      setErrorDescricao(true);
    } else {
      setErrorDescricao(false);
    }
  }

  function montaValueSelect() {
    const optionMotivo = motivosOptions.filter((item) => item.value == motivo);

    return optionMotivo[0] ? optionMotivo[0] : ""
  }


  useEffect(() => {
    trackPromise(
      ticketMotivo
        .index()
        .then((response) => {
          handleTrataMotivos(response.data)
        })
        .catch((error) =>
          swal(
            "Ooops...",
            "Ocorreu um erro ao buscar as dados: " + error,
            "error"
          )
        )
    );

    trackPromise(ticketService.buscarConfiguracoesCriacao()).then((response)=>{
      setConfiguracoesCriacao(response.data);
    })
    
  }, []);

  const handleTrataMotivos = (motivos) => {
    let motivosOptions = [];
    const motivosFiltrados = Object.values(motivos)
          .filter((motivo) => motivo.tipo_acesso == guService.getLocalState('usuarioTipo') || motivo.tipo_acesso == null )

    motivosFiltrados.forEach((item) => {
      motivosOptions.push({
        calculaPrazo: item.id_ticket_motivo,
        value: item.id_ticket_motivo,
        label: item.nome,
        assuntoSugerido: item.assunto_sugerido,
        prazo: item.slo,
        prazo_sla: item.sla,
        tipo_calculo_prazo_sla: item.tipo_calculo_prazo_sla,
        tipo_calculo_prazo_slo: item.tipo_calculo_prazo_slo
      });
    });

    if(Array.isArray(motivo)){
      motivosOptions = motivosOptions.filter((item)=>{
        return motivo.includes(Number(item.value));
      })
    }

    setMotivosOptions(motivosOptions);
    buscarAssunto(motivosOptions);
  }

  function buscarAssunto(motivosOptions) {
    motivosOptions.map((item) => {
      if (item.value === ticket) {
        if (item.assuntoSugerido == null) {
          setAssunto("");
          setCarregou(true);
        } else {
          var regexp =
            /((?:http(s)?:\/\/)?(?:www(\d)?\.)?([\w\-]+\.\w{2,})\/?((?:\?(?:[\w\-]+(?:=[\w\-]+)?)?(?:&[\w\-]+(?:=[\w\-]+)?)?))?(#(?:[^\s]+)?)?)/g;
          if (item.assuntoSugerido.match(regexp)) {
            setLink(item.assuntoSugerido);
            setLiberado(false);
            setCarregou(true);
          } else {
            setAssunto(item.assuntoSugerido);
            setCarregou(true);
          }
        }
      } else {
        setCarregou(true);
      }
    });
  }

  const montaArquivo = useCallback((event) => {
    let reader = new FileReader();
    let file = event.target.files[0];
    if (ValidaTipoArquivo.verificaTipoArquivo(file)) {
      let size =
        event.target.files[0].size == undefined
          ? 0
          : event.target.files[0].size;
      if (size === 0) return "0 Bytes";

      const k = 1024;
      const dm = 2 < 0 ? 0 : 2;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(size) / Math.log(k));
      if (
        parseFloat((size / Math.pow(k, i)).toFixed(dm)) > 10 &&
        " " + sizes[i] == " MB"
      ) {
        Swal.fire({
          titleText: "Erro!",
          text: "O arquivo tem mais de 10mb!",
          icon: "error",
        });
      } else {
        reader.onload = function (upload) {
          let dadosArquivo = {
            conteudo: upload.target.result.split(/base64,/)[1],
            nome: file.name,
          };
          setArquivo(dadosArquivo);
        };
        reader.readAsDataURL(file);
      }
    } else {
      errorToast.fire({
        text: "Tipo de arquivo não suportado, selecione outro ou verifique o mesmo",
      });
    }
  }, []);
 
  function setPrazoOnlyOnce(prazo, type) {
    return moment(new Date()).add(Number(prazo[type]), "minutes").format("DD/MM/YYYY HH:mm")
  }

  function cadastraTicket() {
    if (assunto === "" || assunto == null) {
      verificaError();
      return;
    } else {
      if (motivo === "" || motivo === "Selecione o motivo") {
        verificaError();
        return;
      } else {
        if (descricao === "") {
          verificaError();
          return;
        } else {
          const idProcesso = guService.getLocalState('Processo')
          const chamadoAreaComum = guService.getLocalState('usuarioTipo') == menuService.TIPO_USUARIO_SINDICO_CONDOMINIO && !idProcesso

          let dados = {
            assunto: assunto,
            descricao: descricao,
            id_processo: chamadoAreaComum ? null : idProcesso,
            id_usuario_solicitante: guService.getLocalState("idUsuario"),
            id_ticket_status: 1,
            id_ticket_motivo: motivo,
            dados_arquivo: arquivo,
            id_empreendimento: guService.getLocalState('IdEmpreendimento') ?? null,
            tipo_chamado: chamadoAreaComum ? 'AC' : 'U',
            cnpjCondominio: guService.getLocalState('CPF')
          };
          if(!dados.prazo_sla && !dados.prazo_slo) {
            let motivoOption = motivosOptions.filter((item) => item.value == motivo)
            if(motivoOption && motivoOption.length && motivoOption[0]) {
              dados.prazo_sla = helper.formataMysqlDateHour(setPrazoOnlyOnce(motivoOption[0], 'prazo_sla'));
              dados.prazo_slo = helper.formataMysqlDateHour(setPrazoOnlyOnce(motivoOption[0], 'prazo'));
            }
          }
          if(!botaoAbrirChamadoInativo) {
            setBotaoAbrirChamadoInativo(true)
          }
          trackPromise(
            ticketService.store(dados).then(
              (response) => {
                const protocolo = response.data.protocolo.split("-");
                Swal.fire({
                  titleText: "Chamado aberto",
                  text: `Seu chamado foi aberto com sucesso! N° Protocolo ${
                    protocolo[0] + "-" + helper.zeroPad(protocolo[1], 5)
                  } e Prazo Limite ${moment(response?.data?.prazo_sla).format("DD/MM/YYYY HH:mm")}`,
                  icon: "success",
                });
                setRefresh(true);
                close();
              },
              (error) => {
                Swal.fire({
                  titleText: "Ooops...",
                  text:
                    "Ocorreu um erro ao realizar a sua solicitação: " + error,
                  icon: "error",
                });
              }
            )
          );
        }
      }
    }
  }

  if (carregou) {
    return (
      <div>
        <Form
          className="novaIndicacaoForm"
          onSubmit={handleSubmit(cadastraTicket)}
        >
          <div className="form-chamado-content">
            {link !== "" ? (
              <>
                <Form.Group as={Col} controlId="formAviso">
                  <div className="linkBaixar">
                    Para prosseguirmos, será necessário imprimir, preencher e
                    assinar o formulário disponível no botão "BAIXE AQUI O
                    FORMULÁRIO". Após realizado este passo, clique no botão
                    "PROSSEGUIR COM ABERTURA DO CHAMADO".
                  </div>
                </Form.Group>
                <Form.Group as={Col} controlId="formBaixarForm">
                  <a
                    className="btNovoCadastro"
                    target="_blank"
                    rel='noopener noreferrer'
                    href={link}
                    style={{
                      height: 44,
                      backgroundColor: "var(--dommus-color)",
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#fff",
                      textDecoration: 0,
                    }}
                  >
                    BAIXE AQUI O FORMULÁRIO
                  </a>
                </Form.Group>
                {liberado ? (
                  <></>
                ) : (
                  <Form.Group as={Col} controlId="formBaixarForm">
                    <button
                      className="btNovoCadastro"
                      onClick={(e) => {
                        e.preventDefault();
                        setLiberado(true);
                      }}
                      style={{
                        height: 44,
                        backgroundColor: "#406343",
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#fff",
                      }}
                    >
                      PROSSEGUIR COM ABERTURA DO CHAMADO
                    </button>
                  </Form.Group>
                )}
              </>
            ) : (
              <></>
            )}
            {liberado ? (
              <>
                <Row className="ml-1 mr-1">
                  <Form.Group as={Col} sm={12} md={6} controlId="formAssunto">
                    <Form.Label>
                      1°. Informe o <b>ASSUNTO</b> que deseja tratar: <DommusRequiredSpan />
                    </Form.Label>
                    <Form.Control
                      onBlur={() => verificaError()}
                      name="assunto"
                      ref={register()}
                      type="text"
                      value={assunto}
                      onChange={(event) => setAssunto(event.target.value)}
                    ></Form.Control>
                    <>
                      {errorAssunto ? (
                        <p className="campoError">Campo Obrigatório</p>
                      ) : (
                        <></>
                      )}
                    </>
                  </Form.Group>
                  <Form.Group as={Col} sm={12} md={6} controlId="formMotivo">
                    <Form.Label>
                     2°. Identifique o <b>MOTIVO</b> que se encaixa à sua demanda: <DommusRequiredSpan />
                    </Form.Label>
                    <Select
                      isDisabled={ticket ? Array.isArray(ticket) ? false :  true : false}
                      placeholder="Selecionar"
                      options={motivosOptions}
                      value={motivo ? montaValueSelect() : ""}
                      onBlur={() => verificaError()}
                      onChange={(event) => {
                        setMotivo(event.value);
                      }}
                    />
                    <>
                      {errorMotivo ? (
                        <p className="campoError">Campo Obrigatório</p>
                      ) : (
                        <></>
                      )}
                    </>
                  </Form.Group>
                </Row>
                <Form.Group as={Col} controlId="formDescricao">
                  <Form.Label>
                    3°. Agora, <b>DESCREVA</b> em detalhes a sua solicitação: <DommusRequiredSpan />
                  </Form.Label>
                  <Form.Control
                    onBlur={() => verificaError()}
                    name="descricao"
                    ref={register()}
                    as="textarea"
                    rows={10}
                    value={descricao}
                    onChange={(event) => setDescricao(event.target.value)}
                  ></Form.Control>
                  <>
                    {errorDescricao ? (
                      <p className="campoError">Campo Obrigatório</p>
                    ) : (
                      <></>
                    )}
                  </>
                </Form.Group>
                <Form.Group as={Col} controlId="formNome">
                  <Form.Label>
                    4°. Se desejar, <b>ANEXE</b> um ou mais arquivos:
                  </Form.Label>
                  <DommusMultiFileInput 
                    maximoArquivos={configuracoesCriacao?.qts_maxima_arquivos_abertura_chamado} 
                    extensoesPermitidas={configuracoesCriacao?.extensoes_permitidas_anexos_abertura_chamado} 
                    setArquivosFormatados={setArquivo}
                    tipoUpload='icone'
                  /> 
                </Form.Group>
                              </>
            ) : (
              <></>
            )}
          </div>
          {liberado ? 
            <div className="footer-chamado">
                <OverlayTrigger overlay={<Tooltip>{botaoAbrirChamadoInativo ? 'Você não pode abrir um ticket com o mesmo motivo no mesmo dia.' : 'Confirmar'}</Tooltip>}>
                  <button
                    className="btNovoCadastro"
                    type="submit"
                    style={{
                      backgroundColor: cor,
                      alignSelf: "center",
                      display: "flex",
                      cursor: botaoAbrirChamadoInativo ? 'not-allowed' : 'pointer',
                      opacity: botaoAbrirChamadoInativo ? 0.5 : 1
                    }}
                    disabled={botaoAbrirChamadoInativo}
                  >
                    {textoBtn}
                  </button>
                </OverlayTrigger>
            </div> : <></>
          }
        </Form>
      </div>
    );
  } else {
    return <></>;
  }
}
