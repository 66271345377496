import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import './index.css';
import { trackPromise } from "react-promise-tracker";
import PortalClienteService from '../../../services/PortalClienteService';
import swal from 'sweetalert';
import guService from '../../../services/GuService';
import configuracaoService from "../../../services/ConfiguracaoService";
import Swal from 'sweetalert2';
import DommusLoadingAreaWithoutPromisse from "../../../components/DommusLoading/DommusLoadingAreaWithoutPromisse";
import Select from "react-select";

const optionsGrauRelacionamento = [
  {
    label: "Amigo",
    value: "A"
  },
  {
    label: "Familiar",
    value: "F"
  },
  {
    label: "Vizinho",
    value: "V"
  },
];

export default function NovaIndicacao({ cor, closeModal }) {
    const [dados, setDados] = useState('');
    const [camposObrigatorios, setCamposObrigatorios] = useState({});
    const [carregando, setCarregando] = useState(false);

    const formataCPF = (value) => {
        const cpf = value.replace(/\D/g, ''); // Remove caracteres não numéricos
        const match = cpf.match(/^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})$/);

        if (match) {
          const cpfFormatado = [match[1], match[2], match[3]]
            .filter((group) => !!group)
            .join('.');

          return `${cpfFormatado}${match[4] ? `-${match[4]}` : ''}`;
        }
    };

    const formataTel = (value) => {
        const numero = value.replace(/\D/g, ''); // Remove caracteres não numéricos

        let valorFormatado = numero;

        if (numero.length >= 10) {
          const ddd = numero.slice(0, 2);
          const primeiraParte = numero.slice(2, -4);
          const segundaParte = numero.slice(-4);

          valorFormatado = `(${ddd}) ${primeiraParte}-${segundaParte}`;
        } else if (numero.length >= 8) {
          const primeiraParte = numero.slice(0, -4);
          const segundaParte = numero.slice(-4);

          valorFormatado = `${primeiraParte}-${segundaParte}`;
        }

        return valorFormatado;
      };

    useEffect(() => {
        setCarregando(true);
        configuracaoService.buscarConfiguracoesVisuais().then((response) => {            
            let config = response.data.find(configuracao => configuracao.chave === "configuracao_formulario_indicacao" );            
            if(!config.valor){
              config = response.data.find(configuracao => configuracao.chave === "configuracao_formulario_indicacao" );
            }

            if(config && config?.valor){
              let valor = config?.valor ?? null;
              if(typeof config?.valor === "string"){
                valor = JSON.parse(config?.valor);
              }
              setCamposObrigatorios(valor);
              setCarregando(false);
            }
        }).catch((error)=>{setCarregando(false)});
    }, [])

    const CustomSelect = ({ options, field, form }) => {
      const handleChange = (selectedOption) => {
        form.setFieldValue(field.name, selectedOption);
      };

      return (
        <Select
          styles={{
            control: (baseStyles, state) => ({
                ...baseStyles,
              borderColor: 'var(--dommus-color-2)',
              borderRadius: '10px'
            }),
            valueContainer: (baseStyles, state) => (
              {
                ...baseStyles,
                height: "2.5rem",
              }
            ),
            placeholder: (baseStyles, state) => (
              {
                ...baseStyles,
                color: "#ccc",
                fontStyle: "italic"
              }
            ),
          }}
          onChange={handleChange}
          onBlur={() => form.setFieldTouched(field.name, true)}
          options={options}
          value={field.value}
          placeholder={camposObrigatorios?.campos_formulario?.grau_relacionamento?.placeholder ?? "Selecionar"}
          name='grau_relacionamento'
          isClearable
        />
      );
    };
    
    const dadosIndicado = useMemo(()=>{
        return yup.object({
            nome: yup.string().when([], {
                is: () => !camposObrigatorios?.campos_formulario?.nome?.oculto && (camposObrigatorios?.nome || camposObrigatorios?.campos_formulario?.nome?.obrigatorio),
                then: yup.string().required('Campo obrigatório'),
                otherwise: yup.string(), 
            }).min(2, 'Nome muito pequeno'),
            cpf: yup.string().when([], {
                    is: () => !camposObrigatorios?.campos_formulario?.cpf?.oculto && (camposObrigatorios?.cpf || camposObrigatorios?.campos_formulario?.cpf?.obrigatorio),
                    then: yup.string().required('Campo obrigatório'),
                    otherwise: yup.string(), 
            }).matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'Formato de CPF inválido'),
            email: yup.string().when([], {
                    is: () => !camposObrigatorios?.campos_formulario?.email?.oculto && (camposObrigatorios?.email || camposObrigatorios?.campos_formulario?.email?.obrigatorio),
                    then: yup.string().required('Campo obrigatório'),
                    otherwise: yup.string(), 
            }).email('Formato de e-mail inválido'),
            telefone: yup.string().when([], {
                    is: () => !camposObrigatorios?.campos_formulario?.telefone?.oculto && (camposObrigatorios?.telefone || camposObrigatorios?.campos_formulario?.telefone?.obrigatorio),
                    then: yup.string().required('Campo obrigatório'),
                    otherwise: yup.string(), 
            }).min(8, 'Campo obrigatório'),
            endereco: yup.string().when([], {
                is: () => !camposObrigatorios?.campos_formulario?.endereco?.oculto && (camposObrigatorios?.endereco || camposObrigatorios?.campos_formulario?.endereco?.obrigatorio),
                then: yup.string().required('Campo obrigatório'),
                otherwise: yup.string(), 
            }),
            observacao: yup.string().when([], {
                    is: () => !camposObrigatorios?.campos_formulario?.observacao?.oculto && (camposObrigatorios?.observacao || camposObrigatorios?.campos_formulario?.observacao?.obrigatorio),
                    then: yup.string().required('Campo obrigatório'),
                    otherwise: yup.string(), 
            }),
            grau_relacionamento: yup.object().when([], {
                    is: () => !camposObrigatorios?.campos_formulario?.grau_relacionamento?.oculto && (camposObrigatorios?.grau_relacionamento || camposObrigatorios?.campos_formulario?.grau_relacionamento?.obrigatorio),
                    then: yup.object().nullable().required('Campo obrigatório'),
                    otherwise: yup.object().nullable(), 
            })
        });
    },[camposObrigatorios]) 



    return <div>
        <div className="tituloNovaIndicacao" style={{ backgroundColor: cor ?? "var(--dommus-color)" }} >
            <h5>Nova Indicação</h5>
        </div>
        <Formik
            validationSchema={dadosIndicado}
            onSubmit={(values) => {
                setDados(values);
                let dados = values;
                dados['data'] = new Date().toISOString();
                dados['processo'] = guService.getLocalState("Processo");
                dados['id_indicante'] = guService.getLocalState("idUsuario");
                dados['empreendimento'] = guService.getLocalState("IdEmpreendimento");
                trackPromise(
                    PortalClienteService.salvarIndicacao(dados).then((res) => {
                      Swal.fire({
                          titleText: `Indicação salva com sucesso!`,
                          text: camposObrigatorios?.mensagem_retorno,
                          icon: "success",
                          showCloseButton: false,
                          showCancelButton: false,
                          showDenyButton: false,
                          focusConfirm: false,
                          confirmButtonText: "Ciente",
                          customClass: {
                              confirmButton: "btn btn-success mr-3",
                          },
                          buttonsStyling: false,
                      }).then((result) => {
                      });
                      closeModal(false);
                    })
                ).catch((err) => {
                    swal("Ooops...", "Ocorreu um erro ao salvar indicação: " + err, "error")
                });
            }}

            initialValues={{
                nome: '',
                telefone: '',
                email: '',
                endereco: '',
                cpf: '',
                observacao: '',
                manter_corretor: '',
            }}
        >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
            }) => (<Form className="novaIndicacaoForm">
              {carregando ? <DommusLoadingAreaWithoutPromisse/> :
              <>
                <span>Digite os dados de quem você quer indicar:</span>
                <Row>
                    {(!camposObrigatorios?.campos_formulario?.nome?.oculto || !camposObrigatorios?.campos_formulario?.nome) ?
                      <Col>
                          <label>
                              <div className="inputLabel">Nome:{ (camposObrigatorios?.nome || camposObrigatorios?.campos_formulario?.nome?.obrigatorio) ? <span className="obrigatory">*</span> : <></>}</div>
                          </label>
                          <Field
                              className="form-control"
                              type="text"
                              name="nome"
                              onChange={handleChange}
                              placeholder={camposObrigatorios?.campos_formulario?.nome?.placeholder ?? "Nome"}
                          />
                          {errors.nome && touched.nome ? (
                              <div className="fildError">{errors.nome}</div>
                          ) : null}
                      </Col>
                      : <></>
                    }
                    {(!camposObrigatorios?.campos_formulario?.cpf?.oculto || !camposObrigatorios?.campos_formulario?.cpf) ?
                      <Col >
                          <label>
                              <div className="inputLabel">CPF:{(camposObrigatorios?.cpf || camposObrigatorios?.campos_formulario?.cpf?.obrigatorio) ? <span className="obrigatory">*</span> : <></>}</div>
                          </label>
                          <Field
                              className="form-control"
                              type="text"
                              name="cpf"
                              value={formataCPF(values.cpf)}
                              maxLength="14"
                              pattern="^\d{3}\.\dq{3}\.\d{3}\-\d{2}$"
                              onChange={handleChange}
                              placeholder={camposObrigatorios?.campos_formulario?.cpf?.placeholder ?? "CPF"}
                          />
                          {errors.cpf && touched.cpf ? (
                              <div className="fildError">{errors.cpf}</div>
                          ) : null}
                      </Col> : <></>
                    }
                </Row>
                <Row>
                    {(!camposObrigatorios?.campos_formulario?.email?.oculto || !camposObrigatorios?.campos_formulario?.email) ?
                      <Col>
                          <label>
                              <div className="inputLabel">
                                  E-mail:
                                  {(camposObrigatorios?.email || camposObrigatorios?.campos_formulario?.email?.obrigatorio) ? <span className="obrigatory">*</span> : <></>}
                              </div>
                          </label>
                          <Field
                              className="form-control"
                              type="mail"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              placeholder={camposObrigatorios?.campos_formulario?.email?.placeholder ?? "E-mail"}
                          />
                          {errors.email && touched.email ? (
                              <div className="fildError">{errors.email}</div>
                          ) : null}
                      </Col>
                    : <></>}
                    {(!camposObrigatorios?.campos_formulario?.telefone?.oculto || !camposObrigatorios?.campos_formulario?.telefone) ?
                      <Col >
                          <label>
                              <div className="inputLabel">
                                  Telefone:
                                  {(camposObrigatorios?.telefone || camposObrigatorios?.campos_formulario?.telefone?.obrigatorio) ? <span className="obrigatory">*</span> : <></>}
                              </div>
                          </label>
                          <Field
                              label="telefone"
                              className="form-control"
                              type="text"
                              name="telefone"
                              pattern="^\(\d{2}\) 9?\d{4}-\d{4}$"
                              maxLength="15"
                              value={formataTel(values.telefone)}
                              onChange={handleChange}
                              validate={touched.telefone && !errors.telefone}
                              placeholder={camposObrigatorios?.campos_formulario?.telefone?.placeholder ?? "Telefone"}
                          />
                          {errors.telefone && touched.telefone ? (
                              <div className="fildError">{errors.telefone}</div>
                          ) : null}
                      </Col>
                    :<></>}
                </Row>
                <Row>
                    {(!camposObrigatorios?.campos_formulario?.grau_relacionamento?.oculto || !camposObrigatorios?.campos_formulario?.grau_relacionamento) ?
                      <Col>
                          <label>
                              <div className="inputLabel">
                                  Grau relacionamento:
                                  {(camposObrigatorios?.grau_relacionamento || camposObrigatorios?.campos_formulario?.grau_relacionamento?.obrigatorio) ? <span className="obrigatory">*</span> : <></>}
                              </div>
                          </label>
                          <Field name="grau_relacionamento" component={CustomSelect} options={optionsGrauRelacionamento} />
                          {errors?.grau_relacionamento && touched?.grau_relacionamento ? (
                              <div className="fildError">{errors?.grau_relacionamento}</div>
                          ) : null}
                      </Col>
                    :<></>}
                </Row>
                <Row>
                    {(!camposObrigatorios?.campos_formulario?.endereco?.oculto || !camposObrigatorios?.campos_formulario?.endereco) ?
                      <Col>
                          <label>
                              <div className="inputLabel">
                                  Endereço:
                                  {(camposObrigatorios?.endereco || camposObrigatorios?.campos_formulario?.endereco?.obrigatorio) ? <span className="obrigatory">*</span> : <></>}
                              </div>
                          </label>
                          <Field
                              className="form-control"
                              type="text"
                              name="endereco"
                              value={values.endereco}
                              onChange={handleChange}
                              placeholder={camposObrigatorios?.campos_formulario?.endereco?.placeholder ?? "Endereço"}
                          />
                          {errors.endereco && touched.endereco ? (
                              <div className="fildError">{errors.endereco}</div>
                          ) : null}
                      </Col>
                    :<></>}
                </Row>
                <Row>
                  {(!camposObrigatorios?.campos_formulario?.observacao?.oculto || !camposObrigatorios?.campos_formulario?.observacao) ?
                    <Col >
                    <label>
                            <div className="inputLabel">
                                Observação:
                                {(camposObrigatorios?.observacao || camposObrigatorios?.campos_formulario?.observacao?.obrigatorio) ? <span className="obrigatory">*</span> : <></>}
                            </div>
                        </label>
                        <Field
                            label="observacao"
                            className="form-control"
                            component="textarea"
                            name="observacao"
                            rows={3}
                            value={values.observacao}
                            onChange={handleChange}
                            validate={touched.observacao && !errors.observacao}
                            placeholder={camposObrigatorios?.campos_formulario?.observacao?.placeholder ?? "Observação"}
                        />
                        {errors.observacao && touched.observacao ? (
                            <div className="fildError">{errors.observacao}</div>
                        ) : null}
                    </Col>
                  : <></>}
                </Row>
                <Row className='mt-2'>
                  {(!camposObrigatorios?.campos_formulario?.manter_corretor?.oculto || !camposObrigatorios?.campos_formulario?.manter_corretor) && 
                    <Col className='coluna-checkbox'>
                        <Field
                            label="manter_corretor"
                            className="form-check-input"
                            component="input"  
                            type="checkbox"   
                            id="manter_corretor"
                            name="manter_corretor"
                            checked={values.manter_corretor} 
                            onChange={handleChange}
                            validate={touched.manter_corretor && !errors.manter_corretor}
                        />
                        <label htmlFor="manter_corretor">
                            Manter o mesmo corretor
                        </label>
                        {errors.manter_corretor && touched.manter_corretor ? (
                            <div className="fildError">{errors.manter_corretor}</div>
                        ) : null}
                    </Col>
                  }
                </Row>
                <Row>
                    <button className="btNovaIndicacao" type="submit" style={{ backgroundColor: cor }} > Indicar </button>
                </Row>
              </>}
            </Form>
            )}
        </Formik>
    </div>
}
