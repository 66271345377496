import React, { useEffect } from 'react'
import '../index.css';
import Parser from 'html-react-parser';

export default function ModalBanner({ cor , evento, handleModalHtmlEvento}) {
    let butoesHtml = null;
    useEffect(() => {
        butoesHtml  = document.querySelectorAll('button[data-acao="handleModalHtmlEvento"]');
        butoesHtml.forEach(butao => {
        butao.addEventListener('click', handleModalHtmlEvento)
     });
    }, [])
    
    return (
      <div className='conteiner-dinamico'>
        <div className='row-modal-html'>
          <div className='col-modal-html'>
              {Parser(typeof (evento?.descricao) == 'string' ? evento?.descricao : '')}
          </div>
        </div>
      </div>
    )
}

