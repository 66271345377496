import React, { useState, useEffect, useRef } from 'react';
import { Nav, Tab, Form, InputGroup, Col, Alert, Button, Row } from 'react-bootstrap';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import { Icon } from '@iconify/react';
import overflowMenuHorizontal from '@iconify-icons/carbon/overflow-menu-horizontal';
import './coluna_esquerda.css';
import { parseISO, format } from 'date-fns';
import swal from 'sweetalert';
import { trackPromise } from "react-promise-tracker";
import ticketCategoria from "../../../../../services/TicketCategoriaService";
import ticketMotivo from "../../../../../services/TicketMotivoService";
import ticketStatus from "../../../../../services/TicketStatusService";
import ticketTipo from "../../../../../services/TicketTipoService";
import ticketPrioridade from "../../../../../services/TicketPrioridadeService";
import helper from "../../../../../helpers/format";
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment/moment';
import "react-datepicker/dist/react-datepicker.css";
import ptbr from 'date-fns/locale/pt-BR';
import configuracaoService from '../../../../../services/ConfiguracaoService';
import guService from '../../../../../services/GuService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt, faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import formatDommus from '../../../../../helpers/format';
import { errorToast, successToast } from '../../../../../components/DommusToast'
import { AssistenciaTecnicaService } from '../../../../../services/AssistenciaTecnicaService';

registerLocale("pt-br", ptbr);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

export default function Coluna_esquerda({ ticket }) {
  const [chamado, setChamado] = useState(ticket);
  const [key, setKey] = useState('dados_chamado');
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [autorizacao, setAutorizacao] = useState([{ value: 123, label: 'Usuário 123' }]);
  const [aprovacao, setAprovacao] = useState([{ value: 1, label: 'Usuário 1' }]);
  const [acompanhamento, setAcompanhamento] = useState([{ value: 999, label: 'Usuário 999' }, { value: 900, label: 'Usuário 900' }]);
  const [solicitante, setSolicitante] = useState(chamado?.solicitante?.nome, '');
  const [opv, setOpv] = useState(chamado?.id_oportunidade ? helper.zeroPad(chamado.id_oportunidade, 5) : '');
  const [processo, setProcesso] = useState(chamado?.id_processo ? helper.zeroPad(chamado.id_processo, 7) : '');
  const [organizacao, setOrganizacao] = useState([]);
  const [dataCadastro, setDataCadastro] = useState(chamado?.criado_em || '');
  const [situacao, setSituacao] = useState(chamado?.status?.id_ticket_status || '');
  const [assunto, setAssunto] = useState(chamado?.assunto || '');
  const [descricao, setDescricao] = useState(chamado?.descricao || '');
  const [tipo, setTipo] = useState(chamado?.tipo?.id_ticket_tipo || '');
  const [categoria, setCategoria] = useState(chamado?.categoria?.id_ticket_categoria || '');
  const [motivo, setMotivo] = useState(chamado?.motivo?.id_ticket_motivo || '');
  const [origem, setOrigem] = useState([]);
  const [listaArquivos, setListaArquivos] = useState([]);
  const [prioridade, setPrioridade] = useState(chamado?.prioridade?.id_ticket_prioridade || '');
  const [dataLimite, setDataLimite] = useState(chamado?.prazo || '');
  const [responsavelExecucao, setResponsavelExecucao] = useState(chamado?.id_usuario_responsavel || '');
  const [tags, setTags] = useState([]);
  const [tag, setTag] = useState([]);
  const [configuracaoVistoriaAssistenciaTecnica, setConfiguracaoVistoriaAssistenciaTecnica] = useState(false)
  const [dataVistoriaAssistenciaTecnica, setDataVistoriaAssistenciaTecnica] = useState(null)
  const [listaTipos, setListaTipos] = useState([]);
  const [listaCategorias, setListaCategorias] = useState([]);
  const [listaMotivos, setListaMotivos] = useState([]);
  const [listaStatus, setListaStatus] = useState([]);
  const [listaUsuarios, setListaUsuarios] = useState([]);
  const [listaPrioridades, setListaPrioridades] = useState([]);
  const [dataSolicitacaoAssistenciaTecnica, setDataSolicitacaoAssistenciaTecnica] = useState(null)
  const [nomeVistoriador, setNomeVistoriador] = useState('')
  const [agendamentoVistoriaAssistenciaTecnica, setAgendamentoVistoriaAssistenciaTecnica] = useState(null)

  useEffect(() => {
    trackPromise(
      Promise.all([
        ticketCategoria.index(),
        ticketMotivo.index(),
        ticketStatus.index(),
        ticketTipo.index(),
        ticketPrioridade.index(),
        configuracaoService.buscarConfiguracoesDesk(guService.getLocalState('slug'), ['empreendimentos_permitidos_agendamento_vistoria_assistencia_tecnica_portal_cliente'])
      ]).then((response) => {
        const localCategorias = Object.values(response[0].data);
        setListaCategorias(localCategorias);
        const localMotivos = Object.values(response[1].data);
        setListaMotivos(localMotivos)
        const localStatus = Object.values(response[2].data);
        setListaStatus(localStatus)
        const localTipos = Object.values(response[3].data);
        setListaTipos(localTipos) 
        const localPrioridades = Object.values(response[4].data);
        setListaPrioridades(localPrioridades) 
        const empreendimentosAgendamentoVistoriaAssistenciaTecnica = response[0].data?.configs?.[0]?.valor?.empreendimentos || null
        if(empreendimentosAgendamentoVistoriaAssistenciaTecnica?.length) {
          const configuracaoEmpreendimento = empreendimentosAgendamentoVistoriaAssistenciaTecnica
            .find(empreendimento => Number(empreendimento.id) == Number(guService.getLocalState('IdEmpreendimento')))
          if(configuracaoEmpreendimento) {
            setConfiguracaoVistoriaAssistenciaTecnica(configuracaoEmpreendimento)
          }
        }
      }
      ).catch(error => swal("Ooops...", "Ocorreu um erro ao buscar as dados: " + error, "error"))
    );

  }, [])

  useEffect(() => {
    if(configuracaoVistoriaAssistenciaTecnica) {
      validarTicketParaVistoriaAssistenciaTecnica()
    }
  }, [configuracaoVistoriaAssistenciaTecnica, chamado])

  function validarTicketParaVistoriaAssistenciaTecnica() {
    const componentes = JSON.parse(chamado?.assistenciaTecnica?.configuracao?.componentes || '[]')
    if(componentes.length) {
      const idEtapaVistoria = componentes.findIndex(componente => componente.chave_step == 'agendmento_vistoria') + 1
      if(idEtapaVistoria == chamado.assistenciaTecnica.step) {
        const agendamentoVistoria = JSON.parse(chamado.assistenciaTecnica.valores)?.agendmento_vistoria || null
        setAgendamentoVistoriaAssistenciaTecnica(agendamentoVistoria)
        setDataSolicitacaoAssistenciaTecnica(agendamentoVistoria?.dataSolicitacaoAgendamento && new Date(agendamentoVistoria?.dataSolicitacaoAgendamento) || null)
        if(agendamentoVistoria?.vistoriador) {
          const idVistoriador = agendamentoVistoria.vistoriador.split('surveyor=')?.[1]?.split('&')?.[0]
          buscarDadosVistoriador(idVistoriador)
          const dataVistoria = agendamentoVistoria.vistoriador.split('date=')?.[1]
          if(dataVistoria) {
            setDataVistoriaAssistenciaTecnica(new Date(decodeURIComponent(dataVistoria).replace('+', ' ')))
          }
          return
        }
      }
    }
    setDataVistoriaAssistenciaTecnica(null)
  }

  function buscarDadosVistoriador(idUsuario) {
    guService.buscarDadosUsuario(JSON.stringify([idUsuario]))
      .then(response => setNomeVistoriador(response.data?.[0]?.nome || ''))
      .catch(error => {
        console.log(error)
        console.log(error?.response)
        errorToast.fire({ text: 'Houve um erro ao buscar os dados do vistoriador.' })
      })
  }

  function validarDataSolicitacaoAgendamento(data) {
    return !!configuracaoVistoriaAssistenciaTecnica[formatDommus.removerAcentos(format(data, 'EEE', { locale: ptbr }))]
  }

  function validarHorarioSolicitacaoAgendamento(horario) {
    const horarioValido = configuracaoVistoriaAssistenciaTecnica[formatDommus.removerAcentos(format(dataSolicitacaoAssistenciaTecnica || horario, 'EEE', { locale: ptbr }))].split('-')
    const configHorarioInicial = horarioValido[0].split(':')
    const configHorarioFinal = horarioValido[1].split(':')
    const horarioInicial = new Date()
    const horarioFinal = new Date()
    const agora = new Date()
    horarioInicial.setHours(Number(configHorarioInicial[0]), Number(configHorarioInicial[1]), 0, 0)
    horarioFinal.setHours(Number(configHorarioFinal[0]), Number(configHorarioFinal[1]), 0, 0)
    let validacaoHoje = true
    if(agora.toDateString() == (dataSolicitacaoAssistenciaTecnica || horario).toDateString()) {
      agora.setFullYear(horario.getFullYear(), horario.getMonth(), horario.getDate())
      validacaoHoje = horario > agora
    }
    return horarioFinal >= horario && horario >= horarioInicial && validacaoHoje
  }

  function salvarSolicitacaoAgendamento() {
    if(!dataSolicitacaoAssistenciaTecnica) {
      errorToast.fire({ text: 'Selecione uma data para prosseguir.' })
      
      return
    }
    const agendamentoVistoria = JSON.parse(chamado?.assistenciaTecnica?.valores || '{}')?.agendmento_vistoria
    if(!agendamentoVistoria || !chamado.assistenciaTecnica?.id_assistencia_tecnica) {
      return
    }
    trackPromise(AssistenciaTecnicaService.salvarSolicitacaoAgendamentoVistoriaAssistenciaTecnica(
      chamado.assistenciaTecnica.id_assistencia_tecnica, 
      moment(dataSolicitacaoAssistenciaTecnica).format('YYYY-MM-DD HH:mm')
    )).then(response => {
      const ticketAtualizado = chamado
      ticketAtualizado.assistenciaTecnica = response.data
      setChamado(ticketAtualizado)
      setAgendamentoVistoriaAssistenciaTecnica(response.data?.valores?.agendmento_vistoria)
      successToast.fire({ text: `Solicitação de ${dataVistoriaAssistenciaTecnica && 'reagendamento' || 'agendamento'} realizada com sucesso.` })
    }).catch(error => {
      console.log(error)
      console.log(error?.response)
      errorToast.fire({ text: 'Houve um erro ao fazer a solicitação de agendamento de vistoria da assistência técnica.' })
    })

  }

  function validarDataMinimaSolicitacaoAssistencia() {
    const dataMinima = new Date(configuracaoVistoriaAssistenciaTecnica?.dataInicialPeriodoAgendamento)
    const hoje = new Date()
    
    return dataMinima > hoje ? dataMinima : hoje
  }

  return (
    <div id='esquerda'>
      <Tab.Container
        id="menu_esquerda"
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
        <div className="header">

          <Nav variant="pills" className="menu_esquerda">
            <Col>
              <Nav.Item>
                <Nav.Link eventKey="dados_chamado">Chamado</Nav.Link>
              </Nav.Item>
            </Col>
            {chamado?.categoria?.tipo == 'AT' && configuracaoVistoriaAssistenciaTecnica && (
              <Col>
                <Nav.Item>
                  <Nav.Link eventKey="vistoriaAssistenciaTecnica">Vistoria</Nav.Link>
                </Nav.Item>
              </Col>
            ) || <></>}
            <Col>
              <Nav.Item>
                <Nav.Link
                  className='menu'
                  ref={anchorRef}
                  aria-controls={open === null ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={() => { }}
                  style={{ outline: 'unset' }}
                >
                  <Icon icon={overflowMenuHorizontal} color="#868aa8" width="2rem" height="2rem" />
                </Nav.Link>
              </Nav.Item>
              <Popper style={{ zIndex: 1000 }} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' === null ? 'center top' : 'center bottom' }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={() => { }}>
                        <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={() => { }}>
                          <MenuItem onClick={() => { }}>Encaminhar Chamado</MenuItem>
                          <MenuItem onClick={() => { }}>Opção Um</MenuItem>
                          <MenuItem onClick={() => { }}>Opção Dois</MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>

            </Col>
          </Nav>

        </div>
        <Tab.Content>
          <Tab.Pane eventKey="dados_chamado">
            <Form>
              <Form.Group>
                <div className="inputLabel">Solicitante:</div>
                <InputGroup className="mb-3">
                  <Form.Control
                    aria-label="Solicitante"
                    name="solicitante"
                    value={solicitante === null ? '' : solicitante}
                    onChange={() => { }}
                    disabled
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group>
                <div className="inputLabel">OPV/Processo:</div>
                <InputGroup className="mb-3">
                  <Form.Control
                    aria-label="OPV / Processos"
                    name="opv"
                    value={processo === null ? opv === null ? '' : opv : processo }
                    onChange={() => { }}
                    disabled
                  />
                </InputGroup>
              </Form.Group>
              {/*
              <Form.Group>
                <div className="inputLabel">Organização:</div>
                <InputGroup className="mb-3">
                  <Form.Control
                    aria-label="Organização"
                    name="organizacao"
                    value={organizacao === null ? '' : organizacao}
                    onChange={() => { }}
                  />
                  <InputGroup.Append>
                    <Link to="./"><Icon icon={searchOutlined} color="var(--dommus-color)" width="1.5rem" height="1.5rem" /></Link>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
              */}
              <Form.Group>
                <div className="inputLabel">Data do chamado:</div>
                <Form.Control className="dataChamado" type="datetime" id="date-input"
                  name="data_cadastro" disabled={true}
                  value={dataCadastro === null ? '' : format(parseISO(dataCadastro), "dd/MM/yyyy' às ' HH:mm")}
                  onChange={() => { }}
                />
              </Form.Group>
              {/* <Form.Group>
                <div className="inputLabel">Status</div>
                <Form.Control as="select" className="selectSituacao" value={situacao === null ? "6" : situacao} disabled
                  name="situacao"
                  onChange={() => { }}>

                  {
                    listaStatus.map((value) => (<option key={value.id_ticket_status} value={value.id_ticket_status}>{value.nome}</option>))
                  }
                </Form.Control>
              </Form.Group> */}
              <Form.Group>
                <div className="inputLabel">Assunto:</div>
                <Form.Control as="textarea" rows={3}
                  name="assunto" disabled={true}
                  value={assunto === null ? '' : assunto}
                  onChange={() => { }}
                />
              </Form.Group>
              <Form.Group>
                <div className="inputLabel">Descrição</div>
                <Form.Control as="textarea" rows={3}
                  name="descricao" disabled={true}
                  value={descricao === null ? '' : descricao}
                  onChange={() => { }}
                />
              </Form.Group>
              <Form.Group>
                <div className="inputLabel">Tipo</div>
                <Form.Control as="select" className="selectTipo" value={tipo === null ? "selecionar" : tipo} name="tipo" onChange={() => { }} disabled={true}>
                  <option value="selecionar" > Selecionar </option>
                  {listaTipos.map((value) => (<option key={value.id_ticket_tipo} value={value.id_ticket_tipo}>{value.nome}</option>))}
                </Form.Control>
              </Form.Group>

              <Form.Group>
                <div className="inputLabel">Categoria</div>
                <Form.Control as="select" className="selectCategoria" value={categoria === null ? "selecionar" : categoria} name="categoria" onChange={() => { }} disabled={true}>
                  <option value="selecionar" > Selecionar </option>
                  {listaCategorias.map((value) => (<option key={value.id_ticket_categoria} value={value.id_ticket_categoria}>{value.nome}</option>))}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <div className="inputLabel">Motivo </div>
                <Form.Control as="select" className="selectMotivo"
                  value={motivo === null ? "selecionar" : motivo}
                  name="motivo" disabled={true}
                  onChange={() => { }}>
                  <option value="selecionar"> Selecionar </option>
                  {listaMotivos.map((value) => (<option key={value.id_ticket_motivo} value={value.id_ticket_motivo}>{value.nome}</option>))}
                </Form.Control>
              </Form.Group>
              {/*
              <Form.Group>
                <div className="inputLabel">Origem / Canal</div>
                <Select
                  defaultValue={origem}
                  name="origem"
                  options={origem}
                  className="selectOrigem"
                  classNamePrefix="Selecionar"
                  onChange={(...args) => {
                    console.log(args);
                    console.log(args[0]);
                  }}
                />
                 <Form.Control as="select" className="selectOrigem" value={origem === null ? 'selecionar' : origem} name="origem" onChange={() => {}}>
                  <option disabled value="selecionar"> Selecionar </option>
                  {origem.map((value) => (<option key={value} value={value}>{label}</option>))}
                </Form.Control>
              </Form.Group> */}
            </Form>
          </Tab.Pane>
          <Tab.Pane eventKey="vistoriaAssistenciaTecnica">
            <Row className="px-3">
              <Alert variant={dataVistoriaAssistenciaTecnica && 'warning' || 'info'}>
                <FontAwesomeIcon icon={dataVistoriaAssistenciaTecnica && faExclamationTriangle || faInfoCircle} className="mr-2"/>{dataVistoriaAssistenciaTecnica && <span>
                  Você possui uma vistoria de assistência técnica agendadada
                  {(nomeVistoriador || dataVistoriaAssistenciaTecnica) && (
                    <ul className="mt-3 pl-3">
                      {dataVistoriaAssistenciaTecnica && (
                        <li>
                          <strong>Data: {moment(dataVistoriaAssistenciaTecnica).format('DD/MM/YYYY [às] HH:mm')}</strong>
                        </li>
                      ) || <></>}
                      {nomeVistoriador && (
                        <li>
                          <strong>Vistoriador: {nomeVistoriador}</strong>
                        </li>
                      ) || <></>}
                    </ul> 
                  ) || <></>}
                </span> || <span>
                  Você não possui um agendamento de vistoria de assistência técnica <strong>confirmado</strong> {!agendamentoVistoriaAssistenciaTecnica?.dataSolicitacaoAgendamento && (<>
                    , <strong>selecione uma data abaixo para solicitar uma vistoria.</strong>
                  </>)}
                </span>}
              </Alert>
              {agendamentoVistoriaAssistenciaTecnica?.dataSolicitacaoAgendamento && <Alert variant="info">
                <FontAwesomeIcon className="mr-2" icon={faInfoCircle}/> 
                Você já solicitou o {dataVistoriaAssistenciaTecnica && 'reagendamento' || 'agendamento'} da vistoria de assistência técnica para a data &nbsp;
                <strong>{moment(agendamentoVistoriaAssistenciaTecnica?.dataSolicitacaoAgendamento).format('DD/MM/YYYY [às] HH:mm')}</strong>
              </Alert>}
            </Row>
            <Row>
              <Col sm="12">
                <label className="inputLabel">Solicitar {dataVistoriaAssistenciaTecnica && 'Reagendamento' || 'Agendamento'} Para:</label>
                <ReactDatePicker
                    selected={dataSolicitacaoAssistenciaTecnica}
                    onChange={setDataSolicitacaoAssistenciaTecnica}
                    locale={"pt-br"}
                    showTimeSelect
                    timeFormat="p"
                    timeIntervals={configuracaoVistoriaAssistenciaTecnica?.intervaloVistoriaMinutos || 15}
                    dateFormat="Pp"
                    timeCaption="Horário"
                    className={"form-control"}
                    filterDate={validarDataSolicitacaoAgendamento}
                    filterTime={validarHorarioSolicitacaoAgendamento}
                    maxDate={configuracaoVistoriaAssistenciaTecnica?.dataFinalPeriodoAgendamento && new Date(configuracaoVistoriaAssistenciaTecnica?.dataFinalPeriodoAgendamento) || null}
                    minDate={configuracaoVistoriaAssistenciaTecnica?.dataInicialPeriodoAgendamento && validarDataMinimaSolicitacaoAssistencia() || new Date()}
                />
              </Col>
            </Row>
            <Row className="p-3 justify-content-end">
              <Button variant="success" onClick={salvarSolicitacaoAgendamento}>
                <FontAwesomeIcon icon={faCloudUploadAlt}/> Confirmar
              </Button>
            </Row>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
}