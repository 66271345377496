import crm from './crm';
import guService from './GuService';

const widgetService = {
	buscarWidgetsVisiveis:()=>{
		return crm.get('/widget/monta-widgets', {
			params: {
				idEmpreendimento: guService.getLocalState('IdEmpreendimento')
			}
		});
	},
	buscarArquivosPorWidgetReferencia:(idWidget, refencia, idReferencia, idProcesso=null)=>{
		return crm.get(`/widget/${idWidget}/arquivos/referencia/${refencia}/${idReferencia}${idProcesso ? `/processo/${idProcesso}` : ''}`);
	},	
	buscarDadosFaq:(idWidget, idEmpreendimento)=>{
		return crm.get(`/widget/${idWidget}/faq/empreendimento/${idEmpreendimento}`);
	},	
	buscarParametrosSimuladorRenegociacao: (idWidget) => {
		return crm.get(`simulador-renegociacao/widget/${idWidget}/parametros`);
	},
	buscarValoresRenegociacao: (idProcesso) => {
		return crm.get(`simulador-renegociacao/processo/${idProcesso}/valores-renegociacao`);
	}
};

export default widgetService;