import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import menuService from "../../services/MenuService";
import widgetService from "../../services/WidgetService";
import { Container, Row, Col, Card, CardDeck, Alert, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import DommusToggle from "../../../src/components/DommusToggle";
import ModalDommus from "../../components/Modal";
import NovaIndicacao from "../Relacionamento/NovaIndicacao";
import ModalDistrato from "../Distrato";
import ModalCessaoDireitos from "../CessaoDireitos";
import ModalNovoChamado from "../../components/NovoChamado";
import ModalEvento from "../Eventos";
import DommusIcon from "../../components/DommusIcon";
import Chamados from "../Widgets/MeusChamados/chamados";
import Pagamentos from "../Widgets/MeusPagamentos/pagamentos";
import EvolucaoObra from "../Widgets/EvolucaoObra";
import ApresentacaoEmpreendimento from "../Widgets/ApresentacaoEmpreendimento";
import BannerEmpreendimentoEstatico from "../Widgets/BannerEmpreendimentoEstatico";
import BannerEmpreendimento from "../Widgets/BannerEmpreendimento";
import "./index.css";
import guAPI from "../../services/GuService";
import DistratoService from "../../services/DistratoService";
import Swal from "sweetalert2";
import urlHelper from "../../helpers/UrlHelper";
import guService from "../../services/GuService";
import { trackPromise } from "react-promise-tracker";
import ProcessoService from "../../services/ProcessoService";
import CessaoDireitosService from "../../services/CessaoDireitosService";
import eventoService from "../../services/EventoService";
import { EventosGaleria } from "../Widgets/EventosGaleria";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faFileAlt, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { DommusAlert } from "../../components/DommusAlert";
import VistoriasEntrega from "../ModalVistorias";
import ModalFocoImagens from "../Eventos/ModalImagem/modalFocoImagens";
import ModalMap from "../../components/ModalMap/ModalMap";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ticketService from "../../services/TicketService";
import Chamado from "../Widgets/MeusChamados/Chamado";
import helper from "../../../src/helpers/format"
import { FormularioProcuracao } from "../../components/FormularioProcuracao";
import { NovaAssistenciaTecnica } from "../../components/NovaAssistenciaTecnica";
import { errorToast, successToast } from "../../components/DommusToast";
import { ParcelasEmAtraso } from "../../components/ParcelasEmAtraso";
import { ConfiguracoesDeskContext } from "../../contexts/ConfiguracoesDeskContext";
import { isMobile, isDesktop } from "react-device-detect";
import { Icon } from "@iconify/react";
import envelope from "@iconify-icons/heroicons/envelope";
import dollar from "@iconify-icons/fa/dollar";
import exclamationTriangle from "@iconify-icons/fa/exclamation-triangle";
import eye from "@iconify-icons/heroicons/eye"
import eyeSlash from "@iconify-icons/heroicons/eye-slash"
import { ProcessoContext } from "../../contexts/ProcessoContext";
import { AiOutlineFieldNumber } from "react-icons/ai";
import moment from "moment";
import formatDommus from "../../../src/helpers/format";
import empreendimentoService from "../../services/EmpreendimentoService";
import { Arquivos } from "../Widgets/Arquivos";
import { IntegracaoContext } from "../../contexts/IntegracaoContext";
import { Faq } from "../Widgets/Faq";
import { SimuladorFinanceiro } from "../Widgets/SimuladorFinanceiro";
import { ConfiguracoesVisuaisContextContext } from "../../contexts/ConfiguracoesVisuaisContext";
import { LogNavegacaoService } from "../../services/LogNavegacaoService";

export default function PortalCliente(props) {
  const [openModalNovoChamado, setOpenModalNovoChamado] = useState(false);
  const [openModalNovaIndicacao, setOpenModalNovaIndicacao] = useState(false);
  const [openModalDistrato, setOpenModalDistrato] = useState(false);
  const [openModalCessaoDireitos, setOpenModalCessaoDireitos] = useState(false);
  const [openModalEvento, setOpenModalEvento] = useState(false);
  const [openModalNovaAssistenciaTecnica, setOpenModalNovaAssistenciaTecnica] = useState(false);
  const [corNovaAssistenciaTecnica, setCorNovaAssistenciaTecnica] = useState("");
  const [corChamado, setCorChamado] = useState("");
  const [corIndicacao, setCorIndicacao] = useState("");
  const [corDistrato, setCorDistrato] = useState("");
  const [corEvento, setCorEvento] = useState("");
  const [evento, setEvento] = useState({});
  const [corCessaoDireitos, setCorCessaoDireitos] = useState("");
  const [listaMenus, setListaMenus] = useState([]);
  const [listaWidgets, setListaWidgets] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [ticket, setTicket] = useState("");
  const [permissaoDistrato, setPermissaoDistrato] = useState(false);
  const [permissaoDistratoMotivo, setPermissaoDistratoMotivo] = useState("");
  const [permissaoCessao, setPermissaoCessao] = useState(false);
  const [permissaoCessaoMotivo, setPermissaoCessaoMotivo] = useState("");
  const [cpf, setCpf] = useState(guService.getLocalState('CPF'))
  const [fluxoPagamento, setFluxoPagamento] = useState(null)
  const [eventosImagem, setEventosImagem] = useState([]);
  const [eventosHtml, setEventosHtml] = useState([]);
  const [eventosJanela, setEventosJanela] = useState([]);
  const [eventosAlerta, setEventosAlerta] = useState([]);
  const [eventosGaleria, setEventosGaleria] = useState([]);
  const [eventosMenu, setEventosMenu] = useState([]);
  const [eventosBanner, setEventosBanner] = useState([]);
  const [modalFaturasAtrasadasAberto, setModalFaturasAtrasadasAberto] = useState(false)
  const [parcelasFluxoPagamento, setParcelasFluxoPagamento] = useState([])
  const [dadosEmpreendimento, setDadosEmpreendimento] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [porcentagem, setPorcentagem] = useState(0);
  const [evolucaoObra, setEvolucaoObra] = useState();
  const [categoriasObras, setCategoriasObras] = useState();
  const [tipoVistoria, setTipoVistoria] = useState(null)
  const [openModalVistoriaEntrega, setOpenModalVistoriaEntrega] = useState(false);
  const [openModalEntrega, setOpenModalEntrega] = useState(false);
  const [openModalVistoriaAssistenciaTecnica, setOpenModalVistoriaAssistenciaTecnica] = useState(false);
  const [openModalAgendamentoOutro, setOpenModalAgendamentoOutro] = useState(false);
  const [modalProcuracaoAberto, setModalProcuracaoAberto] = useState(false);
  const [corVistoriaEntrega, setcorVistoriaEntrega] = useState();
  const [corEntrega, setCorEntrega] = useState();
  const [corVistoriaAssistenciaTecnica, setCorVistoriaAssistenciaTecnica] = useState();
  const [tituloAgendamentoOutro, setTituloAgendamentoOutro] = useState();
  const [corAgendamentoOutro, setCorAgendamentoOutro] = useState();
  const [idAgendaOutro, setIdAgendaOutro] = useState(null);
  const [procuracaoVistoria, setProcuracaoVistoria] = useState(null)
  const [conteudoModal, setConteudoModal] = useState({});
  const [exibirBotaoPagarParcelasAtrasadas, setExibirBotaoPagarParcelasAtrasadas] = useState(false)
  const [openModalZoomImagem, setOpenModalZoomImagem] = useState(false);
  const [linkImagem, setLinkImagem] = useState();
  const [quantidadeParcelasPendentes, setQuantidadeParcelasPendentes] = useState(0);
  const [quantidadeAnuncios, setQuantidadeAnuncios] = useState(0);
  const [showEvolucaoObra, setShowEvolucaoObra] = useState(false);
  const recursoLogin = guService.getLocalState('recursoLogin')
  const [modaisHtml, setModaisHtml] = useState([])
  const [modaisJanela, setModaisJanela] = useState([])
  const [modaisImagem, setModaisImagem] = useState([])
  const [modaisBanner, setModaisBanner] = useState([])
  const usuarioTipo = guService.getLocalState('usuarioTipo')
  const [openModalMapJanela, setOpenModalMapJanela] = useState(true);
  const [openModalMapHtml, setOpenModalMapHtml] = useState(true);
  const [openModalMapImagem, setOpenModalMapImagem] = useState(true);
  const [openModalMapBanner, setOpenModalMapBanner] = useState(true);
  const NOME_COMPONENTE_MENUS = 'menu'
  const queryStringParams = new URLSearchParams(useLocation().search);
  const [idChamado, setIdChamado] = useState(queryStringParams.get("idChamado"));
  const [ticketSelecionado, setTicketSelecionado] = useState({});
  const [openModalChamadoUrl, setOpenModalChamadoUrl] = useState();
  const {buscarConfiguracoesPorChave} = useContext(ConfiguracoesDeskContext);
  const {getMobussWithCpf,mobuss} = useContext(IntegracaoContext)
  const processo = guService.getLocalState("Processo")
  const [modaisJanelasDetalhes, setModaisJanelasDetalhes] = useState([]);
  const {listaProcessos} = useContext(ProcessoContext);
  const { aplicarEstilosMobile } = useContext(ConfiguracoesVisuaisContextContext)

  useEffect(() => {
    getMobussWithCpf()
    montaMenu();
}, []);


useEffect(() => {
  montaMenu();
},[mobuss])

  useEffect(() => {
    if(recursoLogin) {
      buscaFuncao(recursoLogin, '', '', '', '')
    }
  }, [recursoLogin])

  useEffect(() => {
    if(idChamado){
      abreModalChamadoComUrlParametro(idChamado);
    }
  }, [idChamado]);


  useEffect(() => {
    EventosVisualizados(eventosJanela, 'janela')
  }, [eventosJanela])

  useEffect(() => {
    EventosVisualizados(eventosImagem, 'imagem')
  }, [eventosImagem])

  useEffect(() => {
    EventosVisualizados(eventosHtml, 'html')
  }, [eventosHtml])

  useEffect(() => {
    EventosVisualizados(eventosBanner, 'banner')
  }, [eventosBanner])

  function abrirModalFocoImagens(link) {
    setLinkImagem(link)
    setOpenModalZoomImagem(true)
  }
  useEffect(() => {
    if(usuarioTipo == menuService.TIPO_USUARIO_SINDICO_CONDOMINIO) {
      buscarDadosEmpreendimentoPorId()

      return
    } else if(formatDommus.valoresVazios.includes(processo)) {
      return
    }
    ProcessoService.buscarDadosProcesso(processo).then((res) => {
      setEvolucaoObra(res.data.evolucao_obra);
      setCategoriasObras(res.data.categorias_obras);
      let dados = [];
      dados.push({
        empreendimento_nome: res.data.processo.empreendimento_nome,
        previsao_entrega:
          res.data.processo.empreendimento_banco_previsao_entrega,
        empreendimento_endereco: res.data.processo.empreendimento_endereco,
        empreendimento_numero: res.data.processo.empreendimento_numero,
        empreendimento_cidade: res.data.processo.empreendimento_cidade,
        empreendimento_uf: res.data.processo.empreendimento_uf,
        empreendimento_cep: res.data.processo.empreendimento_cep,
        empreendimento_bairro: res.data.processo.empreendimento_bairro,
        empreendimento_imagem:
          res.data.processo.empreendimento_imagem_destaque,
        empreendimento_imagem_mobile: res.data.processo.empreendimento_imagem_destaque_mobile
      });
      setDadosEmpreendimento(dados);

      let photos = [];

      Object.values(res.data.evolucao_obra).forEach((item) => {
        JSON.parse(item.caminho_foto).map((link) => {
          photos.push({
            src: link,
            width: 200,
            height: 200,
            ano: item.ano,
            mes: item.mes,
          });
        });
        setPhotos(photos);
      });

      let porcentagem = [];
      Object.values(res.data.evolucao_obra).forEach((item) => {
        porcentagem.push(Number(item.exibir_cliente));
      });
      setPorcentagem(porcentagem.reduce((total, currentElement) => total + currentElement, 0).toFixed(2));
    })
  }, []);

  useEffect(()=>{
    if(isMobile === true && aplicarEstilosMobile){
      const idProcesso = guAPI.getLocalState("Processo")
      if(idProcesso) {
        ProcessoService.buscarFluxoPagamento(idProcesso).then((response)=>{
          let temp = response?.data?.fluxo ?? [];
          temp = temp.filter((fluxo)=>(fluxo.statusParcela !== "quitada"));
          setQuantidadeParcelasPendentes(temp.length);
        }).catch((erro)=>{
          errorToast.fire('Erro ao buscar fluxo de parcelas');
        });
      }
      trackPromise(eventoService.getEventosPorEmpreendimento(guAPI.getLocalState("IdEmpreendimento"))).then((response)=>{
        setQuantidadeAnuncios(response?.data?.length || 0);
      }).catch((erro)=>{
        errorToast.fire('Erro ao buscar anúncios de parcelas');
      })
    }
  },[])




  function buscarDadosEmpreendimentoPorId() {
    empreendimentoService.buscarCondominioPorCNPJ({ cnpj: cpf })
      .then(response => {
        const empreendimento = {
          empreendimento_nome: response.data.condominio.nome,
          previsao_entrega: response.data.empreendimentoBanco.previsao_entrega,
          empreendimento_endereco: response.data.condominio.endereco,
          empreendimento_numero: response.data.condominio.numero,
          empreendimento_cidade: response.data.condominio.cidade,
          empreendimento_uf: response.data.condominio.uf,
          empreendimento_cep: response.data.condominio.cep,
          empreendimento_bairro: response.data.condominio.bairro,
          empreendimento_imagem: response.data.condominio.img_destaque_empreendimento,
          empreendimento_imagem_apresentacao: response.data.condominio.img_destaque_empreendimento
        }
        setDadosEmpreendimento([empreendimento])
      })
      .catch(error => {
        console.log(error)
        console.log(error?.response)
        errorToast.fire({ text: 'Houve um erro ao buscar os dados do empreendimento.' })
      })
  }

  function BotaoWidget({ chave }) {
    switch(chave) {
      case 'meusPagamentos':
        return exibirBotaoPagarParcelasAtrasadas ? <BotaoPagarBoletosAtrasados/> : <></>
      default:
        return <></>
    }
  }

  function BotaoPagarBoletosAtrasados() {
    return <button className="btnExtrato" onClick={() => setModalFaturasAtrasadasAberto(true)} size="sm">Pagar parcelas em atraso</button>
  }

  function EventosVisualizados(eventos, tipo) {

    if (!eventos.length) {
      return [];
    }
    const data = {
      ids_eventos: eventos.map(evento => evento.id_evento),
      cpf
    }
    eventoService.searchLogEventosVisualizadosByIdsEventosAndCpf(data)
      .then(res => {
        const eventosModal = res.data?.ids_eventos_nao_visualizados ? eventos.filter(evento => res.data.ids_eventos_nao_visualizados.includes(evento.id_evento) || evento.recorrente == '1') : []


        if (tipo == 'janela') {
          setOpenModalMapJanela(true)
          setModaisJanela(eventosModal)
        } else if (tipo == 'html') {
          setOpenModalMapHtml(true)
          setModaisHtml(eventosModal)
        } else if (tipo == 'imagem') {
          setOpenModalMapImagem(true)
          setModaisImagem(eventosModal)
        } else if(tipo === 'banner'){
          setOpenModalMapBanner(true);
          setModaisBanner(eventosModal);
        }

      }).catch(err => {
        Swal.fire({
          text: "Houve um problema na busca dos eventos" + err,
        })
      })
  }

  function storeLogEventoVisualizado(id_evento, cpf) {
    eventoService.storeLogVisualizado(id_evento, cpf)
      .then(() => {return})
      .catch(err => {
        Swal.fire({
          title: 'Ooops...',
          text: `Houve um erro ao gerar o log de visualização do evento. ${err}`
        })
      })
  }
  function montaMenu() {
    const idProcesso = guService.getLocalState("Processo")
    menuService.buscarMenusVisiveis().then((response) => {
      response.data.forEach((item) => {
        if(mobuss && mobuss.url && item.nome == 'Meu Imóvel'){
          item.submenus.push({chave:"indisponivel",condicao: true,cor: null,cor_hover: null,html: null,
          icone: "callIn",
          id_agenda: null,
          id_menu: item.submenus.at(-1).id_menu+1,
          id_referencia: null,
          id_ticket_motivo: null,
          link: mobuss.url,
          nome: "Assistência Técnica",
          target: "_blank",
          tipo_acesso: null})
      }
        guAPI.setLocalState("cor-menu-" + item.id_menu, item.cor);
      });
      setListaMenus(response.data.filter(menu => menu.submenus.length || menu.chave == 'eventos'));
     
    });
    widgetService.buscarWidgetsVisiveis().then((response) => {
      setListaWidgets(response.data);
    });
    if(!formatDommus.valoresVazios.includes(idProcesso)) {
      DistratoService.buscarPermissao(idProcesso).then((response) => {
          setPermissaoDistrato(response.data.pode_solicitar);
          setPermissaoDistratoMotivo(response.data.motivo);
        }
      );
      CessaoDireitosService.buscarPermissao(idProcesso).then((response) => {
        setPermissaoCessao(response.data.pode_solicitar);
        setPermissaoCessaoMotivo(response.data.motivo);
      });
      buscarConfiguracoesPorChave(['expediente_calculo_prazo']);
    }
    eventoService.index(guService.getLocalState("IdEmpreendimento"))
      .then((response) => {
        let eventos = response.data.reduce((acumulado, atual) => {
          acumulado[atual.tipo_visualizacao].push(atual);
          return acumulado
        }, {
          Janela: [],
          Galeria: [],
          Alerta: [],
          Menu: [],
          Imagem: [],
          HTML: [],
          Banner: []
        });

        setEventosMenu(eventos.Menu);
        setEventosGaleria(eventos.Galeria);
        setEventosAlerta(eventos.Alerta);
        setEventosJanela(eventos.Janela);
        setEventosImagem(eventos.Imagem);
        setEventosHtml(eventos.HTML);
        setEventosBanner(eventos.Banner);
      });
  }

  function buscaFuncao(chave, cor, ticket, html, referencia, dados = null) {
    if (chave == "abrirChamado") {
      setTicket(ticket);
      setCorChamado(cor);
      setOpenModalNovoChamado(true);
    } else if (chave == "queroIndicar") {
      setCorIndicacao(cor);
      setOpenModalNovaIndicacao(true);
    } else if (chave == "distrato") {
      setCorDistrato(cor);
      if (permissaoDistrato) {
        setOpenModalDistrato(true);
      } else {
        Swal.fire({
          titleText: "Atenção",
          text: permissaoDistratoMotivo,
          icon: "warning",
        });
      }
    } else if (chave == "cessaoDireitos") {
      setCorCessaoDireitos(cor);
      if (permissaoCessao) {
        setOpenModalCessaoDireitos(true);
      } else {
        Swal.fire({
          titleText: "Atenção",
          text: permissaoCessaoMotivo,
          icon: "warning",
        });
      }
    } else if (chave == "evento") {
      setCorEvento(cor);
      setOpenModalEvento(true);
      setEvento(dados);
    } else if (chave == "referencia" || chave == null) {
      Swal.fire({
        titleText: "Documento não disponível",
        icon: "warning",
      });
    } else if (chave == "textAlert") {
      Swal.fire({
        titleText: "Atenção!",
        html: html,
        icon: "info",
      });
    } else if (chave == "modelo") {
      trackPromise(
        ProcessoService.buscarDocumentoImpressaoPorProponente(
          guService.getLocalState("Processo"),
          referencia,
          guAPI.getLocalState("CPF")
        ).then((res) => {
          if (res.data.length > 0) {
            Swal.fire({
              titleText: `Documento de Impressão`,
              text: "Seu documento está disponível",
              icon: "info",
              showCloseButton: false,
              showCancelButton: true,
              focusConfirm: false,
              confirmButtonText: "Ver documento",
              cancelButtonText: "Cancelar",
              customClass: {
                confirmButton: "btn btn-success mr-3",
                cancelButton: "btn btn-secondary mr-3",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.isConfirmed) {
                window.open(res.data, "_blank");
              }
            });
          } else {
            Swal.fire({
              titleText: "Ooops..!",
              text: "Documento não disponível",
              icon: "error",
            });
          }
        })
      );
    } else if (chave == 'agendaVistoriaEntrega'){
      setTicket(ticket);
      setcorVistoriaEntrega(cor);
      setOpenModalVistoriaEntrega(true);
      let conteudo = {html: html}
      setConteudoModal(conteudo)
    } else if (chave == 'agendaEntrega') {
      setTicket(ticket);
      setCorEntrega(cor);
      setOpenModalEntrega(true);
      let conteudo = {html: html}
      setConteudoModal(conteudo)
    } else if (chave == 'agendaVistoriaAssistenciaTecnica') {
      setTicket(ticket);
      setCorVistoriaAssistenciaTecnica(cor);
      setOpenModalVistoriaAssistenciaTecnica(true);
      let conteudo = {html: html}
      setConteudoModal(conteudo)
    } else if (chave == 'agendaOutros') {
      setTicket(ticket);
      setCorAgendamentoOutro(cor);
      setOpenModalAgendamentoOutro(true);
      let conteudo = {html: html}
      setConteudoModal(conteudo)
      if(dados){
        setTituloAgendamentoOutro(dados?.nome ?? "");
        setIdAgendaOutro(dados?.id_agenda ?? null)
      }
    } else if(chave == 'novaAssistenciaTecnica'){
      setTicket(ticket);
      setCorNovaAssistenciaTecnica(cor);
      setOpenModalNovaAssistenciaTecnica(true);
    } else if(chave == 'segundaViaFatura') {
      if(!fluxoPagamento) {
        ProcessoService.buscarFluxoPagamento(guAPI.getLocalState("Processo"))
          .then(response => {
            setFluxoPagamento(response?.data || [])
            abrirSegundaViaFatura(response?.data?.fluxo?.[0])
          })
          .catch(error => {
            console.log(error)
            console.log(error?.response)
            errorToast.fire({ text: 'Houve um erro ao buscar a segunda via do boleto' })
          })
      } else {
        abrirSegundaViaFatura(fluxoPagamento?.fluxo?.[0])
      }
    }
  }

  function abrirSegundaViaFatura(parcela)  {
    if(!parcela) {
      errorToast.fire({ text: 'Boleto não encontrado.' })

      return
    }
    ProcessoService.buscarBoleto(guService.getLocalState("Processo"), parcela.id).then((res) => {
      const retorno = res.data;
      if (retorno.length > 0) {
        Swal.fire({
          titleText: `2ª via de boleto`,
          text: "Seu boleto está disponível para download",
          icon: "info",
          showCloseButton: false,
          showCancelButton: true,
          showDenyButton: true,
          focusConfirm: false,
          confirmButtonText: "Baixar boleto",
          denyButtonText: "Copiar código",
          cancelButtonText: "Cancelar",
          customClass: {
            confirmButton: "btn btn-success mr-3",
            cancelButton: "btn btn-secondary mr-3",
            denyButton: "btn btn-primary mr-3",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.open(retorno[0].urlReport, "_blank");
          } else if (result.isDenied) {
            navigator.clipboard.writeText(retorno[0].digitableNumber);
            successToast.fire({
              text: "Link copiado para a área de transferência.",
            });
          }
        });
      } else {
        Swal.fire({
          titleText: "Boleto não disponível, entre em contato conosco.",
          text: "",
          icon: "warning",
        });
      }
    });
  }

  function buscaNome(item) {
    let nome = item.nome;
    // if (item.chave == "distrato" && permissaoDistrato == false) {
    //   nome = "Distrato solicitado";
    // }
    // if (item.chave == "cessaoDireitos" && permissaoCessao == false) {
    //   nome = "Cessão de direitos solicitada";
    // }
    return nome;
  }

  function handleModalHtmlEvento() {
    setOpenModalMapHtml(false);
    setOpenModalMapBanner(false);
    switch(this.dataset.tipo){
      case 'abrirModalVistoria':
        setOpenModalVistoriaEntrega(true)
        break;
      case 'abrirModalEntrega':
        setOpenModalEntrega(true)
        break;
      case 'abrirModalIndicacao':
        setOpenModalNovaIndicacao(true)
        break;
      default:
        break;
    }
  }

  function buscarProcessosId(id) {
    const processoAtual = guService.getLocalState("Processo");
    if(formatDommus.valoresVazios.includes(id)) {
      return
    }
    ProcessoService.buscarDadosProcesso(id).then((response) => {
      const dados = response.data;
      if (dados.processo != undefined) {
        guService.setLocalState("Processo", dados.processo.processo_id);
        guService.setLocalState("Bloco", dados.processo.unidade_bloco);
        guService.setLocalState("Unidade", dados.processo.unidade_descricao);
        guService.setLocalState(
          "IdEmpreendimento",
          dados.processo.processo_empreendimento
        );
        guService.setLocalState(
          "NomeEmpreendimento",
          dados.processo.empreendimento_nome
        );
        if (id != processoAtual) {
          window.location.reload();
        }
      } else {
        Swal.fire({
          titleText: "CPF Inválido.",
          text: "Não encontramos nenhum processo vinculado a este CPF.",
          icon: "error",
        });
      }
    });
  }

  function abreModalChamadoComUrlParametro(idChamado) {
    ticketService.index(guService.getLocalState("Processo").then(
      (response) => {
        let chamado = response.data.filter(chamado => chamado.id_ticket == idChamado);
        if (chamado[0]?.id_ticket) {
          setTicketSelecionado(chamado[0])
          setOpenModalChamadoUrl(true)
        }else{
          Swal.fire(
            'Não foi encontrado o chamado ' + idChamado + '!',
            'error'
          )
        }
      })).catch(err => {
        Swal.fire(
          'Houve um problema!',
          'Não foi possivel buscar os chamados!',
           err,
          'error'
        )
      })
  }

  function alternarModalProcuracao(tipoVistoria, procuracao=null) {
    setProcuracaoVistoria(procuracao)
    setTipoVistoria(tipoVistoria)
    if(tipoVistoria == 'V') {
      setOpenModalVistoriaEntrega(estadoAtual => !estadoAtual)
    } else {
      setOpenModalEntrega(estadoAtual => !estadoAtual)
    }
    setModalProcuracaoAberto(estadoAtual => !estadoAtual)
  }

  function resolveNovoRecursoLogin(){
    switch (guService.getLocalState('recursoLogin')) {
      case "agendaVistoriaEntrega":
        return "AGENDAMENTO VISTORIA/ENTREGA"
      case "agendaEntrega":
        return "AGENDAMENTO DE ENTREGA DE CHAVES"    
      case "agendaOutros":
        return "AGENDAMENTO"    
      case "simuladorFinanceiro":
        return "SIMULAÇÃO DE RENEGOCIAÇÃO"    
      default:
        return ""
    }
  }

  return (
    <>
      {isDesktop || !aplicarEstilosMobile ?
        <div>
          {listaWidgets.map((item) => (
            <div key={item.id_widget}>
              {item.chave === "bannerEmpreendimento"   && (item.tipo_acesso === usuarioTipo || item.tipo_acesso == null) ? (
                <BannerEmpreendimento
                  dados={dadosEmpreendimento}
                  altura={item.height}
                />
              ) : item.chave === "bannerEstatico"   && (item.tipo_acesso === usuarioTipo || item.tipo_acesso == null) ? (
                <BannerEmpreendimentoEstatico
                  foto={item.url_imagem}
                  altura={item.height}
                />
              ) : (
                <></>
              )}
            </div>
          ))}
        </div>
        : <></>
      }
      {eventosAlerta.length ? (
        eventosAlerta.map(evento => (
          <DommusAlert className="mb-4" key={evento.id_evento}>
            <span
              onClick={() => {
                setOpenModalEvento(true)
                setEvento(evento)
              }}
              style={{cursor: 'pointer'}}
            >
              <FontAwesomeIcon icon={faInfoCircle} /> {`Evento: ${evento.nome} - ${evento.descricao} \nClique aqui para saber mais.`}
            </span>
          </DommusAlert>
        ))
      ) : <></>}
      <div className="paginaHome">
        {isMobile && aplicarEstilosMobile ? 
          <>
            <div className="container-mobile empreendimento-section-mobile">
              <span>{`${guService.getLocalState("NomeEmpreendimento")} - ${guService.getLocalState("Unidade")}`}</span>
                <Dropdown>
                  <Dropdown.Toggle className="perfil-dropdown">
                    <span
                      style={{ alignSelf: "flex-end", marginRight: 10 }}
                    >
                      Contrato:
                    </span>
                    Nº{" "}
                    {guService.getLocalState("Processo")
                      ? guService
                          .getLocalState("Processo")
                          .padStart(5, "0") + " "
                      : ""}
                    {listaProcessos.length > 1 ? (
                      <i className="arrow down"></i>
                    ) : (
                      <></>
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {listaProcessos.map((valor) => {
                      return (
                        <Dropdown.Item
                          key={valor.id}
                          eventKey="4"
                          onClick={() => buscarProcessosId(valor.id)}
                        >
                          <div className="processoBox">
                            <>
                              <FontAwesomeIcon
                                icon={faBuilding}
                                color="#7882A4"
                                style={{ marginRight: 5 }}
                              />
                              {valor.empreendimento_nome}
                            </>
                            <>
                              <AiOutlineFieldNumber
                                color="#8A39E1"
                                style={{ marginRight: 5, marginLeft: 5 }}
                                size={25}
                              />
                              {valor.id}
                            </>
                          </div>
                        </Dropdown.Item>
                      );
                    })}
                    </Dropdown.Menu>
                </Dropdown>
            </div>   

            <div className="container-mobile usuario-section-mobile">
              <span className="nome-section">{`Olá, ${guService.getLocalState("Nome")}`}</span>
              <div className="icones-section">
                <div className="icone-mobile-user"
                  onClick={()=>{
                    window.open("/meus_chamados", "_self");
                  }}
                >
                  <Icon icon={envelope}/>
                  <span>Mensagens</span>
                </div>
                <div className="icone-mobile-user" 
                    onClick={()=>{
                      window.open("/meus_pagamentos", "_self")
                    }}
                >
                  <span className="count-icon-mobile parcelas-icon">{quantidadeParcelasPendentes}</span>
                  <Icon icon={dollar}/>
                  <span>Minhas Parcelas</span>
                </div>
                <div className="icone-mobile-user">
                  <span className="count-icon-mobile comunicado-icon">{quantidadeAnuncios}</span>
                  <Icon icon={exclamationTriangle}/>
                  <span>Comunicados</span>
                </div>
              </div>
            </div>   

            <div className="container-mobile evolucao-mobile-section">
                <div className="evolucao-header">
                  <span>Ver Evolução de Obra</span>
                  <Icon icon={showEvolucaoObra ? eyeSlash : eye} onClick={()=>{ setShowEvolucaoObra((current) => !current)}}/>
                </div>
                {showEvolucaoObra &&
                  <div className="evolucao-conteudo">
                        <EvolucaoObra photos={photos} cor={guAPI.getLocalState("cor_primaria")} porcentagem={porcentagem}
                        evolucaoObra={evolucaoObra} categorias={categoriasObras} />              
                  </div>
                }
            </div>   
          </>
        :<></>}
        {isDesktop || !aplicarEstilosMobile ? 
          <Container fluid>
            <Row>
              <CardDeck className="col-12 cardDeck">
                {listaMenus.map((item, index) => (
                  (item.tipo_acesso == usuarioTipo || item.tipo_acesso == null) ?
                    <Card className="cardAtalhos" key={item.id_menu}>
                      <Card.Body className="cadBoddy">
                        <DommusIcon
                          classe="iconCadastro"
                          cor={item.cor}
                          icone={item.icone}
                        />
                        <Card.Header
                          className="tituloCadastro"
                          style={{ backgroundColor: item.cor }}
                        >
                          <div>{item.nome}</div>
                        </Card.Header>
                        <div className="direita_doc">
                          {item.chave != "eventos" ? (
                            <Card.Text>
                              {item.submenus.map((itemSubmenu) => {
                                return itemSubmenu.link == null ? (
                                  <button
                                    key={itemSubmenu.id_menu}
                                    className="itensMenu"
                                    style={
                                      itemSubmenu.chave == "referencia"
                                        ? { color: "#ddd" }
                                        : { color: "#212529" }
                                    }
                                    onClick={() => {
                                      LogNavegacaoService.gravarLogNavegacao(NOME_COMPONENTE_MENUS, itemSubmenu.id_menu)
                                      buscaFuncao(
                                        itemSubmenu.chave,
                                        itemSubmenu.cor,
                                        itemSubmenu.id_ticket_motivo,
                                        itemSubmenu.html,
                                        itemSubmenu.id_referencia,
                                        itemSubmenu
                                      );
                                    }}
                                  >
                                    <DommusIcon icone={itemSubmenu.icone ?? 'fileTextO'} />
                                    {buscaNome(itemSubmenu)}
                                  </button>
                                ) : itemSubmenu.link.match(
                                  /(mailto:[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$)|(((?:https?)|(?:ftp)):\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gm
                                ) ? (
                                  <a
                                    onClick={() => {
                                      LogNavegacaoService.gravarLogNavegacao(NOME_COMPONENTE_MENUS, itemSubmenu.id_menu)
                                      itemSubmenu.chave != null
                                        ? urlHelper.download(
                                          itemSubmenu.link,
                                          itemSubmenu.target
                                        )
                                        : Swal.fire({
                                          titleText: "Documento não disponível",
                                        });
                                    }}
                                    key={itemSubmenu.id_menu}
                                    className="itensMenu"
                                  >
                                    <DommusIcon icone={itemSubmenu.icone ?? 'fileTextO'} />
                                    {itemSubmenu.nome}
                                  </a>
                                ) : (
                                  <Link
                                    key={itemSubmenu.id_menu}
                                    to={{ pathname: itemSubmenu.link }}
                                    target={itemSubmenu.target}
                                    className="itensMenu"
                                    onClick={() => LogNavegacaoService.gravarLogNavegacao(NOME_COMPONENTE_MENUS, itemSubmenu.id_menu)}
                                  >
                                    <DommusIcon icone={itemSubmenu.icone ?? 'fileTextO'} />
                                    {itemSubmenu.nome}
                                  </Link>
                                );
                              })}
                            </Card.Text>
                          ) : (
                            <Card.Text style={{ overflowX: 'hidden' }}>
                              {eventosMenu.filter(evento => !item.ids_categorias_eventos || item.ids_categorias_eventos.includes(evento.categoria?.id_evento_categoria)).length > 0 ? (
                                eventosMenu.filter(evento => !item.ids_categorias_eventos || item.ids_categorias_eventos.includes(evento.categoria?.id_evento_categoria))
                                  .map((evento, index) => (<>
                                    <button
                                      key={evento.id_evento}
                                      className="itensMenu"
                                      onClick={() => {
                                        buscaFuncao(
                                          "evento",
                                          item.cor,
                                          "",
                                          "",
                                          "",
                                          evento
                                        );
                                      }}
                                    >
                                      <b style={{fontSize: "0.8rem", color: "var(--dommus-color)"}}>{moment(evento.data_inicio).format("DD/MM/YYYY")}</b>: {evento.nome}
                                    </button>
                                    {(evento.categoria.id_evento_categoria === 4 && 
                                      item.ids_categorias_eventos?.includes(evento.categoria?.id_evento_categoria) && 
                                      evento.arquivos.map(arquivo => (
                                        <Row>
                                          <OverlayTrigger overlay={<Tooltip>{arquivo.nome}</Tooltip>}>
                                            <Col>
                                              <a className="itensMenu link-anexos" href={arquivo.link} rel="noopener noreferrer" target="_blank">
                                                <FontAwesomeIcon icon={faFileAlt}/> {(arquivo.nome.length > 30 && arquivo.nome.slice(0, 27).concat('...')) || arquivo.nome}
                                              </a>
                                            </Col>
                                          </OverlayTrigger>
                                        </Row>
                                      ))) || <></>}
                                  </>))
                              ) : (
                                <button
                                  key={index}
                                  className="itensMenu"
                                  disabled
                                  onClick={() => { }}
                                >
                                  Nenhum evento cadastrado
                                </button>
                              )}
                            </Card.Text>
                          )}
                        </div>
                      </Card.Body>
                    </Card>
                    : <></>))}
              </CardDeck>
            </Row>
            <Row>
              {listaWidgets?.map((item) => {
                if (
                  item.chave == "bannerEmpreendimento" ||
                  item.chave == "bannerEstatico"
                ) {
                  return;
                } else {
                  return (
                    (item.tipo_acesso == usuarioTipo || item.tipo_acesso == null || (item.tipo_acesso === "LK" && recursoLogin)) && <>
                      <Col sm={item.tamanho} key={item.id_widget}>
                        <DommusToggle title={item.nome} toggle={true} button={<BotaoWidget chave={item.chave}/>}>
                          {item.chave == "meusPagamentos" ?
                            <Pagamentos 
                              cor={item.cor} 
                              radioValue={"TDS"} 
                              setFluxoPagamento={setParcelasFluxoPagamento} 
                              setExibirBotaoPagarParcelasAtrasadas={setExibirBotaoPagarParcelasAtrasadas} 
                              idWidget={item.id_widget}
                            /> :
                            item.chave == "meusChamados" ?
                              <Chamados cor={item.cor} refresh={refresh} idWidget={item.id_widget}/> :
                              item.chave == "evolucaoObra" ?
                                <EvolucaoObra photos={photos} cor={item.cor} porcentagem={porcentagem} evolucaoObra={evolucaoObra} categorias={categoriasObras} idWidget={item.id_widget}/> :
                                item.chave == "apresentacaoEmpreendimento" ?
                                  <ApresentacaoEmpreendimento dados={dadosEmpreendimento} cor={item.cor} /> :
                                  item.chave == "eventosGaleria" ?
                                    <EventosGaleria 
                                      cor={item.cor} 
                                      eventosGaleria={eventosGaleria} 
                                      setOpenModalEvento={setOpenModalEvento} 
                                      setEvento={setEvento} 
                                      idWidget={item.id_widget}
                                    /> :
                                  item.chave == "arquivos" ?
                                    <Arquivos cor={item.cor} idWidget={item.id_widget} heigth={item?.height}/> :
                                  item.chave == "faq" ?
                                    <Faq cor={item.cor} idWidget={item.id_widget} heigth={item.heigth}/> :
                                  item.chave == "simuladorFinanceiro" ?
                                    <SimuladorFinanceiro cor={item.cor} idWidget={item.id_widget} heigth={item.height}/> :
                                  <></>
                          }
                        </DommusToggle>
                      </Col>
                    </>
                  );
                }
              })}
            </Row>
          </Container>
        :
        <>
          {listaMenus.map((item, index) => (            
            (item.tipo_acesso === usuarioTipo || item.tipo_acesso == null) && 
            (item.chave !== 'eventos' || 
             (item.chave === 'eventos' &&
              eventosMenu.filter(evento => !item.ids_categorias_eventos || item.ids_categorias_eventos.includes(evento.categoria?.id_evento_categoria)).length)
            ) ?
              <div className="container-mobile itens-card-section-mobile" key={index}>
                <span className="header-section">{item.nome}</span>
                <div className="card-icons-section">
                  {item.submenus.map((itemSubmenu) => {
                      return itemSubmenu.link == null ? (
                        <div className="icon-section" 
                              key={itemSubmenu.id_menu}
                              onClick={() => {
                                    buscaFuncao(
                                      itemSubmenu.chave,
                                      itemSubmenu.cor,
                                      itemSubmenu.id_ticket_motivo,
                                      itemSubmenu.html,
                                      itemSubmenu.id_referencia,
                                      itemSubmenu,
                                    );
                                  }}
                        > 
                          <DommusIcon icone={itemSubmenu.icone ?? 'fileTextO'} />
                          <span>{buscaNome(itemSubmenu)}</span>
                        </div>
                      ) : itemSubmenu.link.match(
                        /(mailto:[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$)|(((?:https?)|(?:ftp)):\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gm
                      ) ? (
                        <div className="icon-section" 
                            onClick={() => {
                              itemSubmenu.chave != null
                                ? urlHelper.download(
                                  itemSubmenu.link,
                                  itemSubmenu.target
                                )
                                : Swal.fire({
                                  titleText: "Documento não disponível",
                                });
                            }}
                            key={itemSubmenu.id_menu}
                        >
                          <DommusIcon icone={itemSubmenu.icone ?? 'fileTextO'} />
                          <span>{itemSubmenu.nome}</span>
                        </div>
                      ) : (
                        <Link className="icon-section" 
                            key={itemSubmenu.id_menu}
                            to={{ pathname: itemSubmenu.link }}
                            target={itemSubmenu.target}
                        >
                          <DommusIcon icone={itemSubmenu.icone ?? 'fileTextO'} />
                          <span>{itemSubmenu.nome}</span>
                        </Link>
                      );
                    })}
                    {(item.chave === "eventos" && eventosMenu.length) ? 
                        eventosMenu.filter(evento => !item.ids_categorias_eventos || item.ids_categorias_eventos.includes(evento.categoria?.id_evento_categoria)).map((evento) => (
                          <div className="icon-section" 
                              key={evento.id_evento}
                              onClick={() => {
                                buscaFuncao(
                                  "evento",
                                  item.cor,
                                  "",
                                  "",
                                  "",
                                  evento
                                );
                              }}
                          > 
                            <DommusIcon icone={"megaphone"} />
                            <span>{evento.nome}</span>
                          </div>                          
                        ))
                    :<></>}
                </div>
              </div>
            :<></>
          ))
        }
        {
          listaWidgets?.filter(widget => (widget.tipo_acesso === usuarioTipo || widget.tipo_acesso == null) || (widget.tipo_acesso === 'LK' && recursoLogin))
          .map((widget)=>{
            switch(widget.chave) {
              case 'arquivos':
                return <div className="container-mobile itens-card-section-mobile">
                  <span className="header-section">{widget.nome}</span>
                  <div className="card-icons-section">
                    <Arquivos cor={widget.cor} idWidget={widget.id_widget} heigth={widget?.heigth}/> :
                  </div>
                </div>
              case 'simuladorFinanceiro':
                return <div className="container-mobile itens-card-section-mobile">
                  <span className="header-section">{widget.nome}</span>
                  <div className="card-icons-section">
                    <SimuladorFinanceiro cor={widget.cor} idWidget={widget.id_widget} heigth={widget?.heigth}/>
                  </div>
                </div>
              default:
                return <></>              
            }
          })  
        }
        </>
        }
        {modaisHtml.map((evento, index) => {
          storeLogEventoVisualizado(evento.id_evento, cpf)
          return (
            <Fragment key={index}>
              <ModalMap
                tipo={"html"}
                modalState={openModalMapHtml}
                cor={corEvento}
                conteudo={evento}
                handleModalEvento={handleModalHtmlEvento}
                />
            </Fragment>
          )
        })}
        {modaisImagem.map((evento, index) => {
          storeLogEventoVisualizado(evento.id_evento, cpf)
          return (
            <Fragment key={index}>
              <ModalMap
                tipo={"imagem"}
                modalState={openModalMapImagem}
                cor={corEvento}
                conteudo={evento}
                handleModalEvento={abrirModalFocoImagens}
              />
            </Fragment>
          )
        })}
        {modaisBanner.map((evento, index) => {
          storeLogEventoVisualizado(evento.id_evento, cpf)
          return (
            <Fragment key={index}>
              <ModalMap
                tipo={"banner"}
                modalState={openModalMapBanner}
                cor={corEvento}
                conteudo={evento}
                handleModalEvento={handleModalHtmlEvento}
              />
            </Fragment>
          )
        })}
        {modaisJanela && modaisJanela?.length && modaisJanela.map((evento, index) => {
          Swal.fire({
            title: `${evento?.categoria?.nome ?? "Evento"}: ${evento.nome}`,
            text: `${evento.descricao}`,
            showCancelButton: true,
            cancelButtonText: 'Ver Detalhes'
          }).then(result => {
            if (result.isConfirmed) {
              storeLogEventoVisualizado(evento.id_evento, cpf)
            } else {
              storeLogEventoVisualizado(evento.id_evento, cpf)
              setModaisJanela((current)=> current.shift());
              setModaisJanelasDetalhes((current)=>{
                return ([...current,
                  <ModalMap
                    key={index}
                    tipo={"janela"}
                    modalState={openModalMapJanela}
                    cor={corEvento}
                    conteudo={evento}
                  />
                ])
              })
              return;
            }
          })
        }) || <></>}
        {modaisJanelasDetalhes.map((modal, index)=>{
          return modal;
        })}
        <ModalDommus
          className="modalNovaIndicacao"
          open={openModalNovaIndicacao}
          size={"lg"}
          close={() => {
            setOpenModalNovaIndicacao(false);
          }}
          titulo={"Nova Indicação"}
        >
          <NovaIndicacao
          cor={corIndicacao}
          closeModal={setOpenModalNovaIndicacao}
          />
        </ModalDommus>
        <ModalDommus
          className="modalDistrato"
          open={openModalDistrato}
          size={"md"}
          close={() => {
            setOpenModalDistrato(false);
          }}
          titulo={"Solicitar Distrato"}
        >
          <ModalDistrato cor={corDistrato} />
        </ModalDommus>
        <ModalDommus
          className="modalCessaoDireitos"
          open={openModalCessaoDireitos}
          size={"md"}
          close={() => {
            setOpenModalCessaoDireitos(false);
          }}
          titulo={"Solicitar Cessão Direitos"}
        >
          <ModalCessaoDireitos cor={corCessaoDireitos} />
        </ModalDommus>
        <ModalDommus
          className="modalNovoChamado"
          open={openModalNovoChamado}
          size="lg"
          close={() => {
            setOpenModalNovoChamado(false);
          }}
          titulo={"Solicitação de Demanda"}
        >
          <ModalNovoChamado
            cor={corChamado}
            textoBtn={"Abrir Solicitação"}
            titulo={"Solicitação de Demanda"}
            mostrarBtn={true}
            ticket={ticket}
            disableMotivo={false}
            setRefresh={setRefresh}
            close={() => {
              setOpenModalNovoChamado(false);
            }}
          />
        </ModalDommus>

        <ModalDommus
          className="modalZoomImagem"
          open={openModalZoomImagem}
          size={"lg"}
          close={() => {
            setOpenModalZoomImagem(false);
            setLinkImagem(null);
          }}
          titulo={"Imagem"}
        >
          <ModalFocoImagens link={linkImagem} />
        </ModalDommus>



        <ModalDommus
          className="modalVistoriaEntrega"
          open={openModalVistoriaEntrega}
          size="lg"
          close={() => {
            setOpenModalVistoriaEntrega(false);
          }}
          titulo={"Agendamento de Vistoria"}
        >
          <VistoriasEntrega
            cor={corVistoriaEntrega}
            tipo={"V"}
            ticket={ticket}
            html={conteudoModal.html}
            titulo={'Selecione uma data!'}
            setCloseModal={setOpenModalVistoriaEntrega}
            setRefresh={setRefresh}
            close={() => {
              setOpenModalVistoriaEntrega(false);
            }}
            alternarModalProcuracao={alternarModalProcuracao}
          />
        </ModalDommus>

        <ModalDommus
          className="modalEntrega"
          open={openModalEntrega}
          size="lg"
          close={() => {
            setOpenModalEntrega(false);
          }}
          titulo={"Agendamento da Entrega das Chaves"}
        >
          <VistoriasEntrega
            cor={corEntrega}
            tipo={"E"}
            ticket={ticket}
            html={conteudoModal.html}
            titulo={'Selecione uma data!'}
            setCloseModal={setOpenModalEntrega}
            setRefresh={setRefresh}
            close={() => {
              setOpenModalEntrega(false);
            }}
            eventoAbreModalVistoria={() => {
              setOpenModalEntrega(false);

              setOpenModalVistoriaEntrega(true);

            }}
            alternarModalProcuracao={alternarModalProcuracao}
          />
        </ModalDommus>

        <ModalDommus
          className="modalVistoriaAssistenciaTecnica"
          open={openModalVistoriaAssistenciaTecnica}
          size="lg"
          close={() => {
            setOpenModalVistoriaAssistenciaTecnica(false);
          }}
          titulo={"Abrir Vistoria de Assistência Técnica"}
        >
          <VistoriasEntrega
            cor={corVistoriaAssistenciaTecnica}
            tipo={"A"}
            ticket={ticket}
            html={conteudoModal.html}
            titulo={'Vistoria de Assistência Técnica'}
            setCloseModal={setOpenModalVistoriaAssistenciaTecnica}
            setRefresh={setRefresh}
            close={() => {
              setOpenModalVistoriaAssistenciaTecnica(false);
            }}
          />
        </ModalDommus>

        <ModalDommus
          className="modalVistoriaAssistenciaTecnica"
          open={openModalAgendamentoOutro}
          size="lg"
          close={() => {
            setOpenModalAgendamentoOutro(false);
          }}
          titulo={tituloAgendamentoOutro ?? ""}
        >
          <VistoriasEntrega
            cor={corAgendamentoOutro}
            tipo={"O"}
            ticket={ticket}
            html={conteudoModal.html}
            titulo={tituloAgendamentoOutro}
            setCloseModal={setOpenModalAgendamentoOutro}
            setRefresh={setRefresh}
            idAgenda={idAgendaOutro}
            close={() => {
              setOpenModalAgendamentoOutro(false);
            }}
          />
        </ModalDommus>

        <ModalDommus
          className="modalEventos"
          open={openModalEvento}
          size={"lg"}
          close={() => setOpenModalEvento(false)}
          titulo={"Evento "}
        >
          <ModalEvento cor={corEvento} dados={evento} />
        </ModalDommus>

        <ModalDommus
          className="modalEventos"
          open={openModalChamadoUrl}
          size={"lg"}
          close={() => {
            setOpenModalChamadoUrl(false)
          }}
          titulo={`Chamado Nº: ` + helper.zeroPad(ticketSelecionado.id_ticket, 5)}
        >
          <Chamado chamado={ticketSelecionado} />
        </ModalDommus>

        <ModalDommus
          open={modalProcuracaoAberto}
          close={() => alternarModalProcuracao(tipoVistoria)}
          titulo="Informar Procuração"
          size="lg"
          estatico={true}
        >
          <FormularioProcuracao alternarModalProcuracao={() => alternarModalProcuracao(tipoVistoria)} procuracao={procuracaoVistoria}/>
        </ModalDommus>

        <ModalDommus
          open={openModalNovaAssistenciaTecnica}
          close={() => setOpenModalNovaAssistenciaTecnica(false)}
          titulo="Nova solicitação de assistência técnica"
          size="lg"
          estatico={true}
        >
          <NovaAssistenciaTecnica
            idMotivo={ticket}
            setRefresh={setRefresh}
            close={() => {
              setOpenModalNovaAssistenciaTecnica(false);
            }}
          />
        </ModalDommus>

        <ModalDommus
          open={modalFaturasAtrasadasAberto}
          close={() => setModalFaturasAtrasadasAberto(false)}
          titulo="Parcelas em Atraso"
          size="lg"
        >
          <ParcelasEmAtraso fecharModal={() => setModalFaturasAtrasadasAberto(false)}/>
        </ModalDommus>
      </div>
      {recursoLogin && (<Alert variant="info" className="mx-4">
        <strong><FontAwesomeIcon icon={faInfoCircle}/> Atenção: </strong> 
        Você entrou no Portal do Cliente através de um link encaminhado com a finalidade de {resolveNovoRecursoLogin()}. Portanto, apenas essa função estará disponível para ser acessada. 
        Caso deseje acessar as demais funcionalidades do Portal do Cliente, 
        <a href={`https://portal.dommus.com.br/login/${guService.getLocalState('slug')}`}> CLIQUE AQUI para SAIR</a> e faça o login novamente.
      </Alert>)}
    </>
  );
}
